import { FormatIndentDecrease } from "@material-ui/icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import FormIntra from "./FormIntra";

export default function Intra({ formationsList }) {
  const [form, setForm] = useState(false);
  return (
    <>
      <div className="Title__formation mt-5 mb-5">
        <h1 className="my-3">Des formations en intra</h1>
      </div>
      <p>
        Toutes les formations proposées au catalogue peuvent être transposées et
        adaptées selon les besoins de vos équipes, au sein de votre structure.
        Contactez-nous pour un devis et un programme personnalisé.
      </p>
      <p>
        Vous désirez former vos collaborateurs sur une problématique spécifique
        ? Nous pouvons réaliser l'ensemble de notre catalogue dans le cadre
        d'une formation adaptée à vos équipe.
      </p>
      <p>
        {" "}
        Vous désirez former vos collaborateurs sur une thématque adaptée ou
        spécifique : n'hésitez pas à nous <Link to="/contact">
          contacter
        </Link>{" "}
        pour une étude personnalisée de votre projet.
      </p>
      <p>
        {" "}
        La formule intra-établissement favorise une prise en compte forte des
        spécificités et des problématiques qui vous sont propres et garantit
        également l'acquisition de référentiels de langages et de méthodes
        communs à vos collaborateurs tout en renforçant l'esprit de cohésion.
      </p>
      <hr className="mt-5" />
      <div className="row mt-5 justify-content-center">
        <button className="btn-lg btn-primary" onClick={() => setForm(!form)}>
          {!form ? "Afficher" : "Masquer"} le formulaire
        </button>
        {form && <FormIntra formationsList={formationsList} />}
      </div>
    </>
  );
}
