export default function QuickInfo({ address, phone, siren }) {
  return (
    <div className="plus__desc">
      <p>
        <span className="textBold"> Adresse : </span>
        {address}
      </p>
      <p>
        <span className="textBold"> Numero de téléphone : </span>
        {phone}
      </p>
      <p>
        <span className="textBold"> Numero SIREN : </span>
        {siren}
      </p>
    </div>
  );
}
