import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import logo from "../assets/logo.svg";
import { auth } from "../services";
import "./Header.css";

export default function Header({ logged, setLogged, setAlert }) {
  function authenticatedButtons() {
    return (
      <Fragment>
        <div className="col-md-1 nopadding">
          <Button
            className="mainIcons mainIcons--account"
            style={{ borderRadius: 0 }}
          >
            <Link to="/account">
              <span className="material-icons">manage_accounts</span>
            </Link>
          </Button>
        </div>
        <div className="col-md-1 nopadding">
          <Button
            className="mainIcons mainIcons--logout"
            style={{ borderRadius: 0 }}
            onClick={() =>
              auth.logout(() => {
                setLogged(false);
                setAlert("Vous êtes maintenant déconnecté");
              })
            }
          >
            <span className="material-icons">logout</span>
          </Button>
        </div>
      </Fragment>
    );
  }
  function unauthenticatedButtons() {
    return (
      <div className="col-md-2 nopadding">
        <Button
          className="authBtn mainIcons mainIcons--login"
          style={{ borderRadius: 0 }}
        >
          <Link to="/account">
            <span className="material-icons">login</span>
            <span>Administration</span>
          </Link>
        </Button>
      </div>
    );
  }

  return (
    <header>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <Link to="/">
              <img src={logo} className="logo" height="40" />
            </Link>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-2 nopadding">
                <div className="mainLinks">
                  <div className="mainLinks__title">
                    <Link to="/">ACCUEIL</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2 nopadding">
                <div className="mainLinks">
                  <div className="mainLinks__title">
                    <Link to="/formations">FORMATIONS</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2 nopadding">
                <div className="mainLinks">
                  <div className="mainLinks__title">
                    <Link to="/conseil">CONSEIL</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2 nopadding">
                <div className="mainLinks">
                  <div className="mainLinks__title">
                    <Link to="/apropos">A PROPOS</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2 nopadding">
                <div className="mainLinks">
                  <div className="mainLinks__title">
                    <Link to="/contact">CONTACT</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {logged ? authenticatedButtons() : unauthenticatedButtons()}
        </div>
      </div>
    </header>
  );
}
