import { useState } from "react";
import {
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Switch, Route } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import {
  Register,
  Banner,
  Preview,
  Download,
  QuickInfo,
  FeedBloc,
} from "../components";
import satisfaction from "../assets/pics/indice_satisfaction.jpeg";
import "./Home.css";

/*
 * SETUP COMPONENT
 * ---------------
 */

export default function Home({ formations, feeds }) {
  /*
   * SETUP RENDER
   * ------------
   */
  console.log(`feeds`, feeds);
  return (
    <div>
      <div class="container-fluid nopadding">
        <Banner number={12} />
        <Carousel className="preview animation  ">
          <Preview formations={formations} />
          <div className="col-md-6 offset-md-3">
            <div className="preview__blocs">
              <div class="moreinfos__blocs__feed">
                <div class="feedblocs__list">
                  {feeds != null
                    ? feeds.map((feed, index) => {
                        if (index !== 0) {
                          return (
                            <FeedBloc title={feed.title} links={feed.links}>
                              {feed.description}
                            </FeedBloc>
                          );
                        }
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
          {/* <Preview formations={formations} /> */}
        </Carousel>
      </div>
      <div class="container">
        <h4>Description de l'association</h4>
        <p className="">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit
          necessitatibus explicabo adipisci optio libero perferendis saepe est
          perspiciatis, magnam, iure eos numquam nisi, totam possimus iste unde
          facilis temporibus quos! Lorem ipsum dolor sit amet consectetur
          adipisicing elit. A iste reiciendis perspiciatis, amet laudantium
          ipsum. Voluptates, impedit eveniet. Deleniti, atque nulla! Quis,
          voluptate vel? Consectetur corrupti mollitia ducimus similique et!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae
          debitis amet labore perspiciatis facere voluptates at, vel ab tenetur
          odio sunt, ipsum nesciunt veritatis necessitatibus a quas nulla
          suscipit? Quae!
        </p>
        <p className="">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit
          necessitatibus explicabo adipisci optio libero perferendis saepe est
          perspiciatis, magnam, iure eos numquam nisi, totam possimus iste unde
          facilis temporibus quos! Lorem ipsum dolor sit amet consectetur
          adipisicing elit. A iste reiciendis perspiciatis, amet laudantium
          ipsum. Voluptates, impedit eveniet. Deleniti, atque nulla! Quis,
          voluptate vel? Consectetur corrupti mollitia ducimus similique et!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Nihil blanditiis, sunt ab
          modi in officiis quos recusandae ipsa perspiciatis saepe. Atque
          dignissimos sed doloremque, ut temporibus quas odio beatae fuga.
        </p>
        <div class="moreinfos">
          <div class="row">
            <div class="col-md-12">
              <div class="moreinfos__blocs">
                <hr class="customHR" />
                <div class="row justify-content-around align-items-center">
                  <div class="col-lg-6 col-sm-12">
                    <div class="moreinfos__blocs__plus">
                      {/* <div class="moreinfos__title">
                        <h3>Informations pratiques</h3>
                      </div> */}
                      <QuickInfo
                        address={"60 rue du Rendez-Vous"}
                        phone={"01 43 44 14 14"}
                        siren={"83120961400021"}
                      />
                      <div class="download">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="download__title centeredText">
                              {/* <h4>fichiers à télécharger</h4> */}
                            </div>
                          </div>
                          <Download
                            item="Catalogue 2022"
                            path="/files/Catalogue-OCF-2022.pdf"
                          />
                          <Download
                            item="Reglement interieur"
                            path="/files/reglement.pdf"
                          />
                          <Download
                            item="Plaquette"
                            path="/files/plaquette.pdf"
                          />
                          <Download item="Planning" path="/file/planning.pdf" />
                          <Download
                            item="Livret d'accueil du stagiaire"
                            path="/file/livret_accueil.pdf"
                          />
                          <Download
                            item="Conditions générales de vente"
                            path="conditions_generales_vente.pdf"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-12">
                    <div class="moreinfos__blocs__feed">
                      {/* <div class="moreinfos__title">
                        <h3>Les nouveautés : </h3>
                      </div> */}
                      {/* <div class="feedblocs__list">
                        {feeds != null
                          ? feeds.map((feed, index) => (
                              <FeedBloc title={feed.title} links={feed.links}>
                                {feed.description}
                              </FeedBloc>
                            ))
                          : null}
                      </div> */}
                      <div class="moreinfos__title">
                        <h3>Enquête de satisfaction</h3>
                      </div>
                      <div className="satisfaction-block mt-4 ml-5">
                        <img className="img-fluid" src={satisfaction} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
