import { useState } from "react";
import {
  ShortHeader,
  UpdateProfileForm,
  UpdatePasswordForm,
  History,
  DocView,
} from "../components";
import { Alert } from "@material-ui/lab";
import { auth, users } from "../services";
import {
  CircularProgress,
  Paper,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";

export default function Account({ setLogged }) {
  const [updateSucceed, setUpdateSucceed] = useState(null);
  const [passSucceed, setPassSucceed] = useState(null);
  const [user, setUser] = useState(null);
  const [docs, setDocs] = useState(null);

  function updateDone(data) {
    if (data.status == 200) setUpdateSucceed(true);
  }
  function passDone(data) {
    if (data.status == 200) setPassSucceed(true);
    else setPassSucceed(false);
  }

  if (user === null) {
    users.me((data) => {
      setUser(data);
    });
  }

  console.log(user);

  return (
    <div>
      <ShortHeader
        title="Espace personnel"
        shortIcon="account_circle"
        color="gradient"
      />
      {user ? (
        <div className="container">
          <div className="row space-t space-b">
            <div className="col-sm-12 col-lg-6">
              <div className="row">
                <div className="col-sm-12 space-b">
                  {passSucceed === true ? (
                    <Alert severity="success" className="space-b">
                      Votre mot de passe à bien été mis à jour.
                    </Alert>
                  ) : passSucceed === false ? (
                    <Alert severity="error" className="space-b">
                      Les mots de passe sont différents
                    </Alert>
                  ) : null}
                  <UpdatePasswordForm
                    onSubmit={(fields) =>
                      users.updateMe(fields, (data) => passDone(data))
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 space-b">
                  <History sessions={user.sessions} setDocs={setDocs} />
                </div>
              </div>
              {docs ? (
                <div className="row">
                  <div className="col-sm-12 space-b">
                    <DocView docs={docs} />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-sm-12 col-lg-6">
              {updateSucceed ? (
                <Alert severity="success" className="space-b">
                  Votre profil à bien été mis à jour.
                </Alert>
              ) : null}
              <UpdateProfileForm
                user={user}
                onSubmit={(fields) =>
                  users.updateMe(fields, (data) => updateDone(data))
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
