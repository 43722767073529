import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Grid,
  Paper,
  Box,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "30px",
  },
}));
let key;

export default function LoginForm({ onSubmit }) {
  const classes = useStyles();

  const [password, setPassword] = useState(null),
    [email, setEmail] = useState(null);

  function LogIn() {
    onSubmit({
      email,
      password,
    });
  }

  return (
    <Paper style={useStyles.paper} elevation={8}>
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Connexion espace administrateur
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Login"
                name="login"
                // autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <a href="#">Mot de passe oublié ?</a>
            </Grid> */}
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={LogIn}
              >
                Se connecter
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
}
