import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Grid,
  Paper,
  Box,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "30px",
  },
}));

export default function RegisterForm({ onSubmit }) {
  const classes = useStyles();

  const [firstname, setFirstname] = useState(null),
    [lastname, setLastname] = useState(null),
    [password, setPassword] = useState(null),
    [confirm, setConfirm] = useState(null),
    [email, setEmail] = useState(null),
    [status, setStatus] = useState("student"),
    [role, setRole] = useState("Stagiaire"),
    [company, setCompany] = useState(null),
    [siret, setSiret] = useState(null),
    [mailingAddress, setMailingAddress] = useState(null),
    [mailingZipcode, setMailingZipcode] = useState(null),
    [mailingTown, setMailingTown] = useState(null),
    [billingAddress, setBillingAddress] = useState(null),
    [billingZipcode, setBillingZipcode] = useState(null),
    [billingTown, setBillingTown] = useState(null),
    [phone1, setPhone1] = useState(null),
    [phone2, setPhone2] = useState(null),
    [sended, setSended] = useState(false),
    [allowed, setAllowed] = useState(false);

  return (
    <Paper style={useStyles.paper} elevation={8}>
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Informations</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!firstname && sended}
                name="firstname"
                variant="outlined"
                required
                fullWidth
                label="Prénom"
                autoFocus
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!lastname && sended}
                variant="outlined"
                required
                fullWidth
                label="Nom de famille"
                name="lastname"
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!email && sended}
                variant="outlined"
                required
                fullWidth
                label="Adresse email"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!password && sended}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={!confirm && sended}
                variant="outlined"
                required
                fullWidth
                name="confirm"
                label="Confirmation"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setConfirm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth value="">
                <InputLabel id="demo-simple-select-outlined-label">
                  Situation
                </InputLabel>
                <Select
                  required
                  error={!status && sended}
                  labelId="demo-simple-select-outlined-label"
                  label="status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  defaultValue="student"
                >
                  <MenuItem value={"student"}>Étudiant</MenuItem>
                  <MenuItem value={"employee"}>Salarié</MenuItem>
                  <MenuItem value={"other"}>Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="S'inscrire en tant qu'employeur."
                onChange={(e) =>
                  setRole(role === "Stagiaire" ? "Employeur" : "Stagiaire")
                }
              />
            </Grid>
            {role === "Employeur" ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Entreprise</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!company && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      name="company"
                      label="Entreprise"
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!siret && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      name="siret"
                      label="SIRET"
                      onChange={(e) => setSiret(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!mailingAddress && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Adresse de contact"
                      name="mailing_address"
                      onChange={(e) => setMailingAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!mailingTown && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Ville"
                      name="mailing_town"
                      onChange={(e) => setMailingTown(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!mailingZipcode && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Code postal"
                      name="mailing_zipcode"
                      onChange={(e) => setMailingZipcode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!billingAddress && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Adresse de facturation"
                      name="billing_address"
                      onChange={(e) => setBillingAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!billingTown && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Ville"
                      name="billing_town"
                      onChange={(e) => setBillingTown(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!billingZipcode && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Code postal"
                      name="billing_zipcode"
                      onChange={(e) => setBillingZipcode(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Typography variant="h5">Contact</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="phone_1"
                label="Téléphone principal"
                onChange={(e) => setPhone1(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="phone_2"
                label="Téléphone secondaire"
                onChange={(e) => setPhone2(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setSended(true);
                  onSubmit({
                    firstname,
                    lastname,
                    email,
                    password,
                    confirm,
                    status,
                    role,
                    company,
                    siret,
                    mailingAddress,
                    mailingZipcode,
                    mailingTown,
                    billingAddress,
                    billingZipcode,
                    billingTown,
                    phone1,
                    phone2,
                  });
                }}
              >
                S'inscrire
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
}
