import React, { useState } from "react";
import { ShortHeader, ConseilBloc } from "../components";
import FormConseil from "../components/FormConseil";

export default function Conseil() {
  const [form, setForm] = useState(false);
  return (
    <div>
      <ShortHeader
        title="conseil"
        shortIcon="accessibility_new"
        color="gradient"
      />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="consult">
              <div className="row">
                <div className="col-sm-12 space-b2 mt-5">
                  <h2 className="roboto">
                    Notre intervention de conseil coopérative et intégrative se
                    fonde en priorité sur une coopération avec les
                    établissements et les professionnels avec comme objectif de
                    :
                  </h2>
                  <br />
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      Consolider le sentiment d'appartenance de chacun à une
                      entité avec une identité forte, rassembler autour du
                      projet associatif
                    </li>
                    <li className="list-group-item">
                      Mieux appréhender les situations de travail, pour mieux
                      les gérer et être en capacité d'apporter une solution
                      sur-mesure, adaptée aux besoins.
                    </li>
                    <li className="list-group-item">
                      Percevoir les logiques en présence et les intégrer à son
                      management de la situation, se donner un enjeu et un
                      objectif de progrès à tester.
                    </li>
                    <li className="list-group-item">
                      Pratiquer pour progresser : si possible en testant des
                      outils, des nouveaux comportements.
                    </li>
                    <li className="list-group-item">
                      Renforcer la dynamique et la synergie de travail des
                      professionnels pour améliorer la mutualisation des
                      ressources.
                    </li>
                    <li className="list-group-item">
                      Coopérer en groupe, être un acteur de coopération pour
                      produire de la puissance collective.
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 col-lg-6">
                  <ConseilBloc title="Axe 1 - Développer..." color="grey">
                    <h4 className="mb-4">
                      une culture commune, formaliser et modéliser les pratiques
                      et les processus innovants.
                    </h4>
                    <ul className="list-group">
                      <li className="list-group-item">
                        Consolider les pratiques, mettre en évidence les
                        qualités et les spécificités des établissements
                      </li>
                      <li className="list-group-item">
                        Améliorer le fonctionnement
                      </li>
                      <li className="list-group-item">
                        Aider une équipe de direction à bien fonctionner
                      </li>
                      <li className="list-group-item">
                        Accompagner les prises de fonction des nouveaux cadres
                        d'encadrement
                      </li>
                      <li className="list-group-item ">
                        Coaching de direction
                      </li>
                      <li className="list-group-item ">
                        Accompagnement projet établissement ou service
                      </li>
                    </ul>
                  </ConseilBloc>
                </div>

                <div className="col-md-12 col-lg-6">
                  <ConseilBloc title="Axe 2 - Soutenir..." color="purple">
                    <h4 className="mb-4 roboto">
                      les établissements dans leur organisation et
                      l'accompagnement au changement.
                    </h4>
                    <ul className="list-group">
                      <li className="list-group-item">
                        Accompagner le développement de projets et programmes
                      </li>
                      <li className="list-group-item">
                        Développer une culture de management adapté
                      </li>
                      <li className="list-group-item">
                        Soutenir la transdisciplinarité au sein des équipes
                      </li>
                      <li className="list-group-item ">
                        Coaching d'équipe, team building
                      </li>
                      <li className="list-group-item ">
                        Séances de co-développement
                      </li>
                      <li className="list-group-item ">Méthode projet Agile</li>
                    </ul>
                  </ConseilBloc>
                </div>

                <div className="col-md-12 col-lg-6">
                  <ConseilBloc title="Axe 3 - Prévenir..." color="blue">
                    <h4 className="mb-4 roboto">
                      les risques psychosociaux (RPS).
                    </h4>
                    <ul className="list-group">
                      <li className="list-group-item">
                        Accompagner les processus de prévention des RPS mis en
                        place
                      </li>
                      <li className="list-group-item">
                        Soutenir les professionnels dans leur pratique
                      </li>
                      <li className="list-group-item">
                        Favoriser des espaces d'échanges et de réflexivité
                      </li>
                      <li className="list-group-item ">
                        Facilitation d'espaces d'Intervision, analyse des
                        pratiques
                      </li>
                      <li className="list-group-item ">
                        Accompagnement à la « réparation » d'équipe en
                        difficultés
                      </li>
                      <li className="list-group-item ">
                        Journées thématiques « échanges de pratiques »
                      </li>
                    </ul>
                  </ConseilBloc>
                </div>

                <div className="col-md-12 col-lg-6">
                  <ConseilBloc title="Axe 4 - Intervenir..." color="lightblue">
                    <h4 className="mb-4 roboto">
                      en soutien dans les situations de crises
                    </h4>
                    <ul className="list-group">
                      <li className="list-group-item">
                        Permettre une sortie de crise
                      </li>
                      <li className="list-group-item">
                        Soutenir les professionnels dans la gestion de la crise
                      </li>
                      <li className="list-group-item ">
                        Débriefing, accompagnement matriciel selon les
                        typologies de crise (individuel, collectif)
                      </li>
                      <li className="list-group-item ">
                        Accompagnement à la mise en place de cellule de veille
                      </li>
                      <li className="list-group-item ">Coaching de crise</li>
                    </ul>
                  </ConseilBloc>
                </div>
              </div>
              <div className="row mt-5 justify-content-center">
                <button
                  className="btn-lg btn-primary"
                  onClick={() => setForm(!form)}
                >
                  {!form ? "Afficher" : "Masquer"} le formulaire
                </button>
                {form && <FormConseil />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
