import { Fragment, useState, useEffect } from "react";
import approfondissement from "../assets/pics/Picto_approfondissement.jpg";
import outils from "../assets/pics/Picto_outils.jpg";
import posture from "../assets/pics/Picto_posture_prof.jpg";
import nouvelle_formation from "../assets/pics/picto_new.png";
import presentiel from "../assets/pics/picto_presentiel.png";
import elearning from "../assets/pics/picto_e-learning.png";
import exploration from "../assets/pics/picto_exploration.png";
import Markdown from "markdown-react-js";
import {
  Select,
  TextField,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { candidates, users, apiURL, convertDate } from "../services";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ShortHeader } from "../components";
import { Link } from "react-router-dom";
import FormationContent from "../components/FormationContent";
import FormIndividual from "../components/FormIndividual";

export default function Formation({ location }) {
  let state = location.state || localStorage.getItem("formation");
  const [user, setUser] = useState(null);

  // if (user === null) {
  //   users.me((data) => {
  //     setUser(data);
  //   });
  // }

  const [session, setSession] = useState(null);
  const [number, setNumber] = useState(1);
  const [formVisible, setFormVisible] = useState(false);
  const [eexist, setEexist] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const breakfast = state.sessions.find(elem => elem.type === "breakfast")
  const duration =
    state.sessions.length === 0 ? state.duration : state.sessions[0].duration;

  function SessionsList(sessions) {
    return sessions?.map((session, index) => {
      return (
        <MenuItem value={session._id}>
          <b style={{ marginRight: "5px" }}>{session.location.toUpperCase()}</b>{" "}
          - {convertDate(session.startDate)}
        </MenuItem>
      );
    });
  }

  function handleChange(e) {
    setSession(e.target.value);
  }

  function handleNumber(e) {
    setNumber(e.target.value);
  }

  function displayButton(state, session, done) {
    if (sessionStorage.getItem("jwToken")) {
      if (!done) {
        return (
          <div className="Session">
            <FormControl variant="outlined" style={{ width: "250px" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Session
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Session"
                onChange={handleChange}
              >
                <MenuItem value=""></MenuItem>
                {SessionsList(state.sessions)}
              </Select>
            </FormControl>
            {user && user.role.name === "Employeur" ? (
              <TextField
                id="outlined-basic"
                label="Combien de stagiaires ?"
                type="number"
                onChange={handleNumber}
                style={{ marginLeft: "10px" }}
                variant="outlined"
              />
            ) : null}
            <Button
              fullHeight
              variant="contained"
              color="primary"
              size="large"
              endIcon={<CheckCircleOutlineIcon />}
              onClick={() => {
                candidates.create(session, number, (data) => {
                  if (data.status === 409) {
                    setEexist(true);
                  } else {
                    setDone(true);
                  }
                });
              }}
            >
              S'inscrire
            </Button>
          </div>
        );
      } else {
        return (
          <Alert className="space-t" severity="success">
            Votre candidature à bien été prise en compte
          </Alert>
        );
      }
    } else {
      return null;
    }
  }

  if (!state) {
    return null;
  }

  console.log(`state.sessions`, state.sessions);

  return (
    <div>
      <ShortHeader title="" shortIcon="view_list" color="gradient" />
      <div className="container">
        <div className="fraction space-t space-b">
          <div className="col-xs-12 space-b">
            <div className="Title__formation mt-4">
              <h1 id="title">{state.title}</h1>
              <div className="row justify-content-center">
                {state.icon && (
                  <img
                    className="img-fluid my-4"
                    style={{ width: "120px" }}
                    src={`${apiURL}/${state.icon}`}
                    alt={`Logo niveau ${state.level.toString()}`}
                  />
                )}
              </div>
            </div>
          </div>

          {/* FORMATION DESCRIPTION */}
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="Metadata__Item">
                <span className="Metadata__Title h-title-color2">
                  <i class="fas fa-book-open"></i> Axe {state.axe_id}
                </span>
                <Markdown text={state.axe} />
              </div>
              {state.breakfast && (
                <div className="Metadata__Item">
                  <span className="Metadata__Title h-title-color2">
                    <i className="fas fa-coffee"></i> Petit déjeuner
                  </span>
                  <span className="Metadata__Text">
                    <dt>Date : {convertDate(state.breakfast.date)}</dt>
                  </span>
                  <span className="Metadata__Text">
                    Prix : {state.breakfast.price} €
                  </span>
                </div>
              )}
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="Metadata__Item">
                <span className="Metadata__Title">
                  <i className="fa fa-user-friends"></i> Public concerné
                </span>
                <span className="Metadata__Text">{state.public}</span>
              </div>
              {state.prerequisite ? (
                <div className="Metadata__Item">
                  <span className="Metadata__Title">
                    <i className="fa fa-book"></i> Prérequis
                  </span>
                  <span className="Metadata__Text">{state.prerequisite}</span>
                </div>
              ) : null}
              <div className="Metadata__Item">
                <span className="Metadata__Title">
                  <i className="fa fa-calendar-minus"></i> Sessions
                </span>
                <span className="Metadata__Text">
                  <dl>
                    {state.open_for_inter ? (
                      state.sessions.map((session, index) => {
                        const { location, startDate } = session;
                        return (
                          <Fragment>
                            <dt>Date : {convertDate(startDate)}</dt>
                            <dd>Lieu : {location}</dd>
                            {/* <dt></dt>
                          <dd></dd> */}
                          </Fragment>
                        );
                      })
                    ) : (
                      <>
                        <dt>Formation Intra</dt>
                        <dd>sur devis</dd>
                      </>
                    )}
                  </dl>
                </span>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="Metadata__Item">
                <span className="Metadata__Title h-title-color2">
                  <i className="fas fa-clock"></i> Horaires
                </span>
                <span className="Metadata__Text">09h30 - 13h00</span>
                <span className="Metadata__Text">14h00 - 17h30</span>
              </div>
              <div className="Metadata__Item">
                <span className="Metadata__Title h-title-color2">
                  <i className="fa fa-hourglass-half"></i> Durée
                </span>

                <span className="Metadata__Text">
                  {duration.toString() + " jours"} ({duration * 7} heures)
                </span>
                {/* <span className="Metadata__Text">
                    {state.sessions[0].duration > 1
                      ? state.sessions[0].duration + " jours"
                      : "Une journée"}{" "}
                    ({state.sessions[0].duration === 3 ? 21 : 14} heures)
                  </span> */}
              </div>
              <div className="Metadata__Item ">
                <span className="Metadata__Title h-title-color2">
                  <i className="fas fa-euro-sign"></i> Tarifs
                </span>
                <dl>
                  {state.price_continuing && (
                    <>
                      <dt>Formation continue</dt>
                      <dd>{state.price_continuing} €</dd>
                    </>
                  )}
                  {state.price_individual && (
                    <>
                      <dt>Formation individuelle</dt>
                      <dd>{state.price_individual} €</dd>
                    </>
                  )}
                  <dt>Formation Intra</dt>
                  <dd>sur devis</dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="">
              {/* <button className="btn"> */}
              <i class="far fa-arrow-alt-circle-left"></i>{" "}
              <Link to="/formations">
                <span className="">Retour à la liste</span>
              </Link>
              {/* </button> */}
            </div>
          </div>
          <hr className="my-5" />

          {/* FORMULAIRE */}
          {state.open_for_inter && (
            <>
              <div className="Session justify-content-center">
                <FormControl variant="outlined" style={{ width: "250px" }}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Session
                  </InputLabel>
                  {state.sessions && (
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Session"
                      value={session}
                      onChange={handleChange}
                    >
                      <MenuItem value=""></MenuItem>
                      {SessionsList(state.sessions)}
                    </Select>
                  )}
                </FormControl>
                {/* <TextField
              id="outlined-basic"
              label="Combien de stagiaires ?"
              type="number"
              value={number}
              onChange={handleNumber}
              style={{ marginLeft: "10px" }}
              variant="outlined"
            /> */}
                <Button
                  fullHeight
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<CheckCircleOutlineIcon />}
                  onClick={() => {
                    setFormVisible(true);
                  }}
                >
                  S'inscrire
                </Button>
              </div>
              {session && <FormIndividual />}
              <hr className="my-5" />
            </>
          )}

          {/* CONTENT ACCORDIONS */}
          <FormationContent
            formation_goals={state.formation_goals}
            pedagogical_goals={state.pedagogical_goals}
            content={state.content}
            pedagogical_method={state.pedagogical_method}
            evaluation_method={state.evaluation_method}
          />

          {/* <div className="col-sm-12">{displayButton(state, session, done)}</div>
          {eexist ? (
            <div className="col-sm-12">
              <Alert className="space-t" severity="warning">
                Vous avez déjà candidaté à cette formation
              </Alert>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  );
}
