import { ShortHeader } from "../components";
import {
  FormControl,
  InputLabel,
  Card,
  Select,
  MenuItem,
  Link,
  div,
  Paper,
  Box,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";

export default function Contact() {
  return (
    <div className="space-b2">
      <ShortHeader
        title="contactez-nous"
        shortIcon="contact_mail"
        color="gradient"
      />
      <div className="container space-t2">
        <div className="row">
          <div className="col-12">
            <h3>N'hesitez pas à nous contacter</h3>
            <p>
              Veuillez fournir vos informations, nous reviendrons vers vous.
            </p>
            <p className="h-title-color3 textBold">
              <i class="fas fa-phone-volume"></i> 01 43 44 67 21
            </p>
          </div>

          <div className="col-lg-8 col-sm-12 space-b">
            <div>
              <form noValidate>
                <div className="row">
                  <div className="col-6 space-t">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      label="Nom"
                      name="name"
                    />
                  </div>
                  <div className="col-6 space-t">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="company"
                      label="Entreprise"
                    />
                  </div>
                  <div className="col-6 space-t">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      label="Adresse e-mail"
                      name="email"
                    />
                  </div>
                  <div className="col-6 space-t">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="phone"
                      label="N° de téléphone"
                    />
                  </div>
                  <div className="col-12 space-t">
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                      rows={8}
                      name="subject"
                      label="Que pouvons faire pour vous ?"
                    />
                  </div>
                  <div className="col-sm-12 col-lg-6 offset-lg-6 space-t">
                    <Button fullWidth variant="contained" color="primary">
                      Envoyer
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 space-b2">
            <Card
              style={{ padding: "20px", height: "100%", background: "#d4d4fc" }}
            >
              <h3>Besoin d'aide ?</h3>

              <p>
                Vous avez une demande spécifique ?<br></br>
                <br></br> N'hésitez pas et contactez nous par mail ou
                directement par téléphone au 01.43.44.67.21
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
