import { Button } from "@material-ui/core";
import { ShortHeader } from "../components";
import orga from "../assets/pics/Picto_orga.jpg";
import echange from "../assets/pics/Picto_echange.jpg";
import pedagogie from "../assets/pics/Picto_pedagogie.jpg";
import equipe from "../assets/pics/Picto_equipe.jpg";
import handicap from "../assets/pics/Picto_handicap.png";
import qualite from "../assets/pics/Picto_demarche_qualité.jpg";
import datadock from "../assets/pics/datadock.png";
import qualiopi from "../assets/pics/Logo_qualiopi.png";

export default function About() {
  return (
    <div>
      <ShortHeader title="A propos" shortIcon="help" color="gradient" />

      <div className="container">
        <div className="fraction space-t">
          <div className="col-xs-12 space-b mt-4">
            <div className="TextBlock">
              <div className="TextBlock__Title">
                <img src={orga} width="80" alt="" />
                <h2>OC&F...</h2>
                {/* <span>OC&F</span> */}
              </div>
              <p>
                est une association loi 1901, ayant pour objet la
                sensibilisation et la formation, le conseil et l’accompagnement
                de projets, la recherche et le développement, en particulier
                dans les domaines de l’addictologie, des conduites à risques, de
                la santé mentale et de la précarité sociale.
                <br />
                <br />
                C’est aussi un organisme de formation du secteur médico psycho
                social et plus particulièrement expert dans le champ de
                l’addictologie. Il s’est donné pour missions de concevoir et
                d’animer des formations qui permettent de :
                <br />
                <br />
              </p>
              <ul>
                <li>
                  développer des compétences collectives et individuelles{" "}
                </li>
                <li>développer des outils et des approches innovantes </li>
                <li>favoriser la transdisciplinarité</li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 space-b mt-4">
            <div className="TextBlock__Title">
              <img src={echange} width="80" alt="" />
              <h2>Nos formations...</h2>
              {/* <span>Formation</span> */}
            </div>
            <p>
              ont pour objectif l’approfondissement des connaissances des
              acteurs et visent la consolidation de pratiques professionnelles
              déjà existantes, tant dans le savoir (connaissance), le
              savoir-être (technique et relationnel) que le savoir-faire
              (jugement professionnel structuré à l’aide d’outils) en s’appuyant
              sur les modélisations techniques.
              <br />
              <br />
              Les objectifs de formation s’appuient essentiellement sur les
              réalités de terrain. Les sessions de formation se construisent en
              articulant les demandes des acteurs, les situations qu’ils vivent
              et les publics accompagnés.
              <br />
              <br />
              Les formations d’OC&F s’appuient sur l’approche expérientielle
              pour déployer des programmes de formations intégratifs (diversité
              des formateurs et intervenants, apports théoriques et practicum).
              <br />
              <br />
              Le cadre des formations dispensées est orienté vers la
              professionnalisation d’acteurs de terrain, pour leur permettre une
              appropriation dans leurs pratiques professionnelles de nouveaux
              outils, protocoles et programmes. Pour ce faire, les programmes
              sont pensés pour s’appuyer sur les compétences collectives et
              individuelles.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-sm-12 nopadding">
            <div className="BgTextBlock BgTextBlock--purple">
              <h3>Des formations inter-établissements</h3>
              <p>
                Elles rassemblent des professionnels issus de différentes
                structures autour d’un même thème. Basées sur l’échange des
                pratiques, ces actions de formation permettent de développer ses
                connaissances tout en partageant son expérience avec des pairs
              </p>
              <img
                className="BgTextBlock__Bg"
                src="https://calendarmedia.blob.core.windows.net/assets/6aca1ac8-0865-4d48-b42a-a0e1463b6e85.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 nopadding">
            <div className="BgTextBlock BgTextBlock--blue">
              <h3>Des formations intra</h3>
              <p>
                Toutes les formations proposées au catalogue peuvent être
                transposées et adaptées selon les besoins de vos équipes, au
                sein de votre structure.Contactez-nous pour un devis et
                programme personnalisé.
              </p>
              <img
                className="BgTextBlock__Bg"
                src="https://blog.vantagecircle.com/content/images/2019/03/blog-image--workplace-orientation-01.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="fraction space-t">
          <div className="col-xs-12 space-b mt-4">
            <div className="TextBlock__Title">
              <img src={pedagogie} width="80" alt="" />
              <h2>Notre pédagogie...</h2>
              {/* <span>Pédagogie</span> */}
            </div>
            <p>
              place l’apprenant comme acteur de sa formation par et pour une
              dynamique collective.
              <br />
              <br />
              Parce que nous avons déjà fait nos preuves en interne sur
              l’ensemble des structures de l’association Oppelia, nous nous
              attachons à ce que nos interventions allient apports théoriques et
              mises en situation pratique. OC&F promeut une pédagogie où les
              expériences professionnelles de chaque stagiaire sont valorisées
              et mises en avant pour une réflexion commune autour de celles-ci
              et des apports théoriques présentés.
              <br />
              <br />
              Pendant la formation, des outils et médias numériques soutiennent
              l’expérience de terrain des intervenants. S’y ajoutent, en
              fonction de l’objectif de la formation, des mises en situation,
              des practicum et des études de cas.
              <br />
              <br />
              La démarche pédagogique d’OC&F allie conseil, clinique et
              formation et s’appuie sur l’autonomie et les pratiques réflexives
              des professionnels.
            </p>
          </div>

          <div className="col-xs-12 space-b">
            <div className="TextBlock__Title">
              <img src={equipe} width="80" alt="" />
              <h2>L’équipe de formateurs...</h2>
              {/* <span>Formateur</span> */}
            </div>
            <p>
              est issue d’une étroite coopération de professionnels, de
              formateurs et d’usagers experts.
              <br />
              <br />
              Nos intervenants sont mobilisés sur la base de leurs expériences,
              de leur qualité pédagogique et de leur capacité à réfléchir sur
              leurs pratiques. Ils sont encadrants de centres de soins,
              d’accompagnement et de prévention en addictologie ou en réduction
              des risques, psychologues, éducateurs spécialisés,
              psychomotriciennes, mais aussi usagers et médiateurs santé pair.
              <br />
              <br />
              L’équipe d’OC&F place les problématiques du « terrain » au centre
              de sa démarche avec pour mission la compréhension, la prévention,
              le traitement des conduites addictives et des situations de
              vulnérabilité.Soucieux de proposer des programmes
              transdiscplinaires, les formateurs co-construisent les programmes
              et travaillent régulièrement à leur amélioration dans le souci
              d’être au plus près des besoins des équipes.
            </p>
          </div>
          <div className="col-xs-12 space-b mt-4">
            <div className="TextBlock__Title">
              <img src={qualite} width="80" alt="" />
              <h2>Une démarche qualité</h2>
            </div>
            <p>
              OC&F répond aux exigences du décret qualité du 30 juin 2015. Nos
              actions de formation sont référençables par l’ensemble des
              financeurs de la formation professionnelle. L’ensemble des
              formations sont co-évaluées afin de favoriser l’amélioration
              continue des contenus et méthodes proposées.
            </p>
            <img src={datadock} width="300" alt="logo datadock" />
            <br />
            <br />
            <p>
              Notre démarche qualité s'inscrit aussi dans le cadre de la loi
              n°2018-771 du 5 Septembre 2018 pour la liberté de choisir son
              avenir professionnel.
            </p>
            <p>
              OC&F a été évalué et jugé conforme par l'AFNOR aux exigences du
              Décret n°2019-564 du 6 Juin 2019 relatif à la qualité des actions
              de la formation professionnelle et au Décret n°2019-565 du 6 Juin
              2019 relatif au référentiel national sur la qualité des actions
              concourant au développement des compétences.
            </p>
            <p>
              OC&F bénéficie depuis 2021 de la certification{" "}
              <span className="font-weight-bold">Qualiopi</span>.
            </p>
            <img
              className="img-fluid"
              src={qualiopi}
              alt="logo qualiopi"
              width="300"
            />
            <p>
              L'agrément "Qualiopi" vise à :
              <ul>
                <li>
                  Attester de la qualité du processus mis en oeuvre par les
                  prestataires d'actions concourant au développement des
                  compétences.
                </li>
                <li>
                  Permettre une plus grande lisibilité de l'offre de formation
                  auprès des entreprises et des stagiaires
                </li>
              </ul>
            </p>
          </div>
          <div className="col-xs-12 space-b mt-4">
            <div className="TextBlock__Title">
              <img src={handicap} width="80" alt="" />
              <h2>Une attention particulière au handicap</h2>
              {/* <span>Handicap</span> */}
            </div>
            <p>
              OC&F s'engage à développer l'accessibilité de ses formations à
              tout public, et notamment l’inclusion du public en situation de
              handicap.
              <br />
              <br />
              OC&F dispose à présent d’une référente handicap, chargée
              d’accueillir et d’accompagner les apprenants en situation de
              handicap.
              <br />
              <br />
              Si vous êtes en situation de handicap et que votre état de santé
              peut représenter une difficulté pour vous former, nous mettrons
              tout en œuvre pour rendre notre formation accessible et vous
              fournir tous les aménagements nécessaires dans la mesure du
              possible. Pour cela, n’hésitez pas à{" "}
              <span className="font-weight-bold">
                contacter notre référente handicap en amont de la formation :
                Mme Lydia ROCHE –
                <a href="mailto:contact-ocf-formation@oppelia.fr">
                  contact-ocf-formation@oppelia.fr
                </a>
              </span>{" "}
              Elle vous proposera un entretien pour analyser vos besoins en
              toute confidentialité.
            </p>
          </div>
          <hr />
          <div className="col-xs-12">
            <div className="Cta space-b">
              <div className="Cta__Text">
                <h2 className="Cta__Title">Inscrivez-vous</h2>
                <p className="Cta__Msg">
                  Toute l'équipe d'OCF vous attend pour vous donner la meilleurs
                  formation, alors choisissez celle qui vous interesse et
                  rejoignez-nous.
                </p>
                <Button variant="contained" color="primary">
                  Inscrivez-vous
                </Button>
              </div>
              <div className="Cta__Image"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
