import React from "react";
import { CircularProgress } from "@material-ui/core";
import SearchBar from "./SearchBar";
import { FormationBloc } from ".";

export default function Catalogue({
  formationsList,
  handleInputChange,
  handleAxeChange,
  handleMonthChange,
  handleSearch,
  accordionShow,
  query,
}) {
  console.log(`accordionShow`, accordionShow);
  return (
    <>
      <div className="Title__formation mt-5 mb-5">
        <h2 className="my-3">{formationsList.length} formations disponibles</h2>
      </div>
      <SearchBar
        handleInputChange={handleInputChange}
        handleAxeChange={handleAxeChange}
        handleMonthChange={handleMonthChange}
        handleSearch={handleSearch}
      />

      <div className="accordion" id="accordionCatalogue">
        <div className="accordion-item">
          <h2 className="accordion-header" id="CatalogueHeadingOne">
            <div
              className="accordion-button TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <h2 className="TextBlock__Title axe-title-color1">
                Axe 1 : Approches d'accompagnement expérientielles et innovantes
              </h2>
            </div>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="CatalogueHeadingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {formationsList ? (
                formationsList
                  .filter((elem) => elem.axe_id === 1)
                  .map((formation, index) => (
                    <FormationBloc
                      key={index}
                      title={formation.title}
                      state={formation}
                    ></FormationBloc>
                  ))
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="CatalogueHeadingTwo">
            <div
              className="accordion-button collapsed TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <h2 className="TextBlock__Title axe-title-color2">
                Axe 2 : Troubles co-occurents ou spécifiques
              </h2>
            </div>
          </h2>
          <div
            id="collapseTwo"
            className={
              query
                ? "accordion-collapse collapse show"
                : "accordion-collapse collapse"
            }
            aria-labelledby="CatalogueHeadingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {formationsList ? (
                formationsList
                  .filter((elem) => elem.axe_id === 2)
                  .map((formation, index) => (
                    <FormationBloc
                      key={index}
                      title={formation.title}
                      state={formation}
                    ></FormationBloc>
                  ))
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="CatalogueHeadingThree">
            <div
              className="accordion-button collapsed TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              <h2 className="TextBlock__Title axe-title-color3">
                Axe 3 : Prévention, intervention précoce et réduction des
                risques
              </h2>
            </div>
          </h2>
          <div
            id="collapseThree"
            className={
              query
                ? "accordion-collapse collapse show"
                : "accordion-collapse collapse"
            }
            aria-labelledby="CatalogueHeadingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {formationsList ? (
                formationsList
                  .filter((elem) => elem.axe_id === 3)
                  .map((formation, index) => (
                    <FormationBloc
                      key={index}
                      title={formation.title}
                      state={formation}
                    ></FormationBloc>
                  ))
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="CatalogueHeadingFour">
            <div
              className="accordion-button collapsed TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="true"
              aria-controls="collapseFour"
            >
              <h2 className="TextBlock__Title axe-title-color4">
                Axe 4 : Transdisciplinarité et coopération avec les usagers
              </h2>
            </div>
          </h2>
          <div
            id="collapseFour"
            className={
              query
                ? "accordion-collapse collapse show"
                : "accordion-collapse collapse"
            }
            aria-labelledby="CatalogueHeadingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              {formationsList ? (
                formationsList
                  .filter((elem) => elem.axe_id === 4)
                  .map((formation, index) => (
                    <FormationBloc
                      key={index}
                      title={formation.title}
                      state={formation}
                    ></FormationBloc>
                  ))
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
