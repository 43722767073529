import { Link } from "react-router-dom";
import { NextSession } from ".";
import "./FormationBloc.css";

export default function FormationBloc({ title, state, children }) {
  localStorage.setItem("formation", state);
  return (
    <Link className="List__Item" to={{ pathname: `/formation`, state: state }}>
      <div className="List__Data">
        <div className="List__ItemTitle">
          <h3>
            {title} {!state.open_for_inter && "(en équipe uniquement)"}
          </h3>
        </div>
        <div className="List__ItemMeta">
          {/* <NextSession sessions={state.sessions} /> */}
        </div>
        <div className="List__ItemDesc">{children}</div>
      </div>
      <div className="List__More">
        <i className="fa fa-search"></i>
      </div>
    </Link>
  );
}
