import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Grid,
  Paper,
  Box,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "30px",
  },
}));

export default function UpdateProfileForm({ onSubmit, user }) {
  const classes = useStyles();

  let role;
  try {
    role = user.role.name;
  } catch (e) {}

  const [firstname, setFirstname] = useState(user.firstname),
    [lastname, setLastname] = useState(user.lastname),
    [status, setStatus] = useState(user.status),
    [company, setCompany] = useState(
      user.employer ? user.employer.company : null
    ),
    [siret, setSiret] = useState(user.employer ? user.employer.SIRET : null),
    [mailingAddress, setMailingAddress] = useState(
      user.employer ? user.employer.mailing_address.address : null
    ),
    [mailingZipcode, setMailingZipcode] = useState(
      user.employer ? user.employer.mailing_address.zipcode : null
    ),
    [mailingTown, setMailingTown] = useState(
      user.employer ? user.employer.mailing_address.town : null
    ),
    [billingAddress, setBillingAddress] = useState(
      user.employer ? user.employer.billing_address.address : null
    ),
    [billingZipcode, setBillingZipcode] = useState(
      user.employer ? user.employer.billing_address.zipcode : null
    ),
    [billingTown, setBillingTown] = useState(
      user.employer ? user.employer.billing_address.town : null
    ),
    [phone_1, setPhone1] = useState(user.phone_1),
    [phone_2, setPhone2] = useState(user.phone_2),
    [sended, setSended] = useState(false),
    [allowed, setAllowed] = useState(false);

  return (
    <Paper style={useStyles.paper}>
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Modifier mon profile</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!firstname && sended}
                name="firstname"
                variant="outlined"
                required
                fullWidth
                label="Prénom"
                autoFocus
                defaultValue={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!lastname && sended}
                variant="outlined"
                required
                fullWidth
                label="Nom de famille"
                name="lastname"
                defaultValue={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth value="">
                <InputLabel id="demo-simple-select-outlined-label">
                  Situation
                </InputLabel>
                <Select
                  required
                  error={!status && sended}
                  labelId="demo-simple-select-outlined-label"
                  label="status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  defaultValue={status}
                >
                  <MenuItem value={"student"}>Étudiant</MenuItem>
                  <MenuItem value={"employee"}>Salarié</MenuItem>
                  <MenuItem value={"other"}>Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="phone_1"
                label="Téléphone principal"
                defaultValue={phone_1}
                onChange={(e) => setPhone1(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="phone_2"
                label="Téléphone secondaire"
                defaultValue={phone_2}
                onChange={(e) => setPhone2(e.target.value)}
              />
            </Grid>
            {role === "Employeur" ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Entreprise</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!company && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      name="company"
                      label="Entreprise"
                      defaultValue={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!siret && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      name="siret"
                      label="SIRET"
                      defaultValue={siret}
                      onChange={(e) => setSiret(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!mailingAddress && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Adresse de contact"
                      name="mailing_address"
                      defaultValue={mailingAddress}
                      onChange={(e) => setMailingAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!mailingTown && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Ville"
                      name="mailing_town"
                      defaultValue={mailingTown}
                      onChange={(e) => setMailingTown(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!mailingZipcode && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Code postal"
                      name="mailing_zipcode"
                      defaultValue={mailingZipcode}
                      onChange={(e) => setMailingZipcode(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={!billingAddress && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Adresse de facturation"
                      name="billing_address"
                      defaultValue={billingAddress}
                      onChange={(e) => setBillingAddress(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!billingTown && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Ville"
                      name="billing_town"
                      defaultValue={billingTown}
                      onChange={(e) => setBillingTown(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!billingZipcode && sended && role === "Employeur"}
                      variant="outlined"
                      required
                      fullWidth
                      label="Code postal"
                      name="billing_zipcode"
                      defaultValue={billingZipcode}
                      onChange={(e) => setBillingZipcode(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setSended(true);
                  onSubmit({
                    firstname,
                    lastname,
                    status,
                    company,
                    siret,
                    mailingAddress,
                    mailingZipcode,
                    mailingTown,
                    billingAddress,
                    billingZipcode,
                    billingTown,
                    phone_1,
                    phone_2,
                  });
                }}
              >
                Mettre à jour
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
}
