import React from "react";
import { NextSession } from ".";
import { apiURL, convertDate } from "../services";
import "./ParcoursBloc.css";

export default function Parcours({
  title,
  state,
  breakfast,
  handleCheckBoxChange,
}) {
  const formationTitle = breakfast ? `${title} (P'tit déj')` : title;
  return (
    <div className="List__Item">
      <div className="List__Data">
        <div className="List__ItemTitle">
          <h3>{formationTitle}</h3>
        </div>
        <div className="List__ItemMeta">
          {/* <NextSession sessions={state.sessions} /> */}
        </div>
        <div className="List__ItemDesc">
          <div className="row justify-content-evenly m-4">
            <div className="col-md-3 col-sm-6">
              {breakfast ? (
                <img
                  src={`${apiURL}/icons/breakfast.png`}
                  style={{ width: "20%" }}
                  alt="petit déjeuner"
                />
              ) : (
                <img
                  className="img-fluid"
                  style={{ width: "30%" }}
                  src={`${apiURL}/${state.icon}`}
                  alt={`niveau ${state.level.toString()}`}
                />
              )}
            </div>
            <div className="col-md-3 col-sm-6">
              {breakfast ? (
                <>
                  <dt>Date :</dt>
                  <dd>{convertDate(breakfast.date)}</dd>
                </>
              ) : state.sessions && state.sessions.length !== 0 ? (
                <>
                  <dt>Date :</dt>
                  <dd>{convertDate(state.sessions[0].startDate)}</dd>
                </>
              ) : (
                <>
                  <dt>Date :</dt>
                  <dd>Sans date</dd>
                </>
              )}
            </div>
            <div className="col-md-3 col-sm-6">
              {breakfast ? (
                <>
                  <dt>Durée :</dt>
                  <dd>Une matinée</dd>
                </>
              ) : state.sessions && state.sessions.length !== 0 ? (
                <>
                  <dt>Durée :</dt>
                  <dd>{state.sessions[0].duration} jours</dd>
                </>
              ) : (
                <>
                  <dt>Durée :</dt>
                  <dd>A définir</dd>
                </>
              )}
            </div>
            <div className="col-md-2 col-sm-6">
              {breakfast ? (
                <>
                  <dt>Tarif :</dt>
                  <dd>{breakfast.price} €</dd>
                </>
              ) : (
                <>
                  {state.price_individual && (
                    <>
                      <dt>Tarif individuel : </dt>
                      <dd>{state.price_individual} €</dd>
                    </>
                  )}
                  {state.price_continuing && (
                    <>
                      <dt>Tarif formation continue :</dt>
                      <dd>{state.price_continuing} €</dd>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="row justify-content-end">
              <div className="col-md-3 form-check">
                <input
                  className="form-check-input "
                  type="checkbox"
                  value=""
                  onChange={(e) => {
                    handleCheckBoxChange(e, formationTitle);
                  }}
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  Ajouter
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
