import axios from 'axios'

const prodURL = "https://oppeliacf.herokuapp.com"
const testURL = "http://10.91.75.105:8000"

const apiURL =
    process.env.NODE_ENV === "development" ?
    "http://localhost:8000" :
    `${prodURL}`;

const $ = axios.create({
    // DEVELOPPEMENT
    baseURL: process.env.NODE_ENV === "development" ? "http://localhost:8000/api" : `${prodURL}/api`,

    // LOCAL SERVE
    // baseURL: "http://10.91.75.105:8000/api",

    // PRODUCTION 
    // baseURL: "/api" ?,
    timeout: 60000
})

$.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

$.interceptors.response.use(
    (response) => {
        return response
    },
    (err) => {
        console.log(err)
        return Promise.reject(err)
    }
)


let jwt = sessionStorage.getItem('jwToken')

if (jwt) $.defaults.headers.common['Authorization'] = `Bearer ${jwt}`

const
    sessions = {
        count: (cb) => $.get(`/sessions/count`)
            .then(data => cb(data)),
        find: (cb) => $.get(`/sessions`)
            .then(data => cb(data)),
        findOne: (id, cb) => $.get(`/sessions/${id}`)
            .then(data => cb(data)),
    },
    formations = {
        count: (cb) => $.get(`/formations/count`)
            .then(data => cb(data)),
        find: (cb) => $.get(`/formations`)
            .then(data => cb ? cb(data) : null),
        findOne: (id, cb) => $.get(`/formations/${id}`)
            .then(data => cb(data)),

    },
    categories = {
        count: (cb) => $.get(`/categories/count`)
            .then(data => cb(data)),
        find: (cb) => $.get(`/categories`)
            .then(data => cb(data)),
        findOne: (id, cb) => $.get(`/categories/${id}`)
            .then(data => cb(data)),

    },
    counsels = {
        count: (cb) => $.get(`/counsels/count`)
            .then(data => cb(data)),
        find: (cb) => $.get(`/counsels`)
            .then(data => cb(data)),
        findOne: (id, cb) => $.get(`/counsels/${id}`)
            .then(data => cb(data)),

    },
    feeds = {
        count: (cb) => $.get(`/feeds/count`)
            .then(data => cb(data)),
        find: (cb) => $.get(`/feeds`)
            .then(data => cb(data)),
        findOne: (id, cb) => $.get(`/feeds/${id}`)
            .then(data => cb(data)),
    },
    auth = {
        login: ({
                email,
                password
            }, cb) => $.post(`/auth/login`, {
                identifier: email,
                password: password,
            })
            .then(data => {
                let jwt = data.data.jwt

                sessionStorage.setItem('jwToken', jwt)
                sessionStorage.setItem('userId', data.data.user._id)

                $.defaults.headers.common['Authorization'] = `Bearer ${jwt}`

                cb(data)
            }, err => cb(err.response)),
        logout: (cb) => {
            delete $.defaults.headers.common['Authorization']

            sessionStorage.removeItem('jwToken')
            sessionStorage.removeItem('userId')

            cb({
                data: "JWT removed from session"
            })
        },
        register: ({
            firstname,
            lastname,
            email,
            password,
            confirm,
            status,
            role,
            company,
            siret,
            mailingAddress,
            mailingZipcode,
            mailingTown,
            billingAddress,
            billingZipcode,
            billingTown,
            phone1,
            phone2
        }, cb) => {

            if (!email || !password || !confirm || !firstname || !lastname) {
                return cb({
                    data: "ERROR: Some fields are empty"
                })
            }

            if (password !== confirm) {
                return cb({
                    data: "ERROR: Password are different"
                })
            }

            $.post('/auth/register', {
                    email: email,
                    password: password,
                    firstname: firstname,
                    lastname: lastname,
                    status: status,
                    company: company,
                    role: role,
                    siret: siret,
                    mailingAddress: mailingAddress,
                    mailingZipcode: mailingZipcode,
                    mailingTown: mailingTown,
                    billingAddress: billingAddress,
                    billingZipcode: billingZipcode,
                    billingTown: billingTown,
                    phone1: phone1,
                    phone2: phone2
                })
                .then(data => {
                    console.log(data)
                    return cb(data)
                })
                .catch(e => cb(e.response))
        }
    },
    users = {
        me: (cb) => $.get(`/users/me`)
            .then(data => cb(data.data)),
        updateMe: ({
            firstname,
            lastname,
            email,
            password,
            confirm,
            status,
            role,
            company,
            siret,
            mailingAddress,
            mailingZipcode,
            mailingTown,
            billingAddress,
            billingZipcode,
            billingTown,
            phone_1,
            phone_2
        }, cb) => {
            if (password && !confirm) {
                return cb({
                    data: "ERROR: Some fields are empty"
                })
            }

            if (password !== confirm) {
                return cb({
                    data: "ERROR: Password are different"
                })
            }

            $.put('/users/me', {
                    email: email,
                    password: password,
                    firstname: firstname,
                    lastname: lastname,
                    status: status,
                    role: role,
                    company: company,
                    siret: siret,
                    mailingAddress: mailingAddress,
                    mailingZipcode: mailingZipcode,
                    mailingTown: mailingTown,
                    billingAddress: billingAddress,
                    billingZipcode: billingZipcode,
                    billingTown: billingTown,
                    phone_1: phone_1,
                    phone_2: phone_2
                })
                .then(data => {
                    return cb(data)
                })
        }
    },
    candidates = {
        create: (id, places, cb) => {
            $.post(`/candidates`, {
                    session: id,
                    places: places
                })
                .then(data => cb(data))
                .catch(e => cb(e.response))
        }
    }

const convertDate = (date) => {
    return new Date(date).toLocaleDateString("fr-FR", {
        // weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
    })
}

const convertDateSpan = (startDate, endDate) => {
    const start = new Date(startDate).toLocaleDateString("fr-FR", {
        // weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
    })
    const end = new Date(endDate).toLocaleDateString("fr-FR", {
        // weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
    })
    return `Du ${start} au ${end}`
}

export {
    apiURL,
    $,
    sessions,
    formations,
    categories,
    counsels,
    feeds,
    auth,
    users,
    candidates,
    convertDate,
    convertDateSpan
}