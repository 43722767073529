export default function ConseilBloc({ title, children, color }) {
  return (
    <div className="accordion">
      <div className="axis">
        <h3
          className={"handorgel__header handorgel__header--" + color}
          data-open
        >
          <div className="handorgel__header__button roboto">{title}</div>
        </h3>
        <div className="handorgel__content" data-open>
          <div className="handorgel__content__inner my-4">{children}</div>
        </div>
      </div>
    </div>
  );
}
