export default function FeedBloc({ title, links, children }) {
  return (
    <div className="moreinfos__feedblocs">
      <div className="row">
        <div className="col-md-2">
          <div className="feed__icon">
            <span className="material-icons">feed</span>
          </div>
        </div>
        <div className="col-md-10">
          <div className="feed__content">
            <div className="feed_title textBigger textlightbold pt-3">
              <p>{title}</p>
            </div>
            <div className="feed_desc">
              <p>{children}</p>
              {links &&
                links.map((link) => (
                  <p>
                    <a href={link} target="_blank">
                      {link}
                    </a>
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
