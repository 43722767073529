import { useState } from 'react';
import { 
	FormControl, 
	InputLabel, 
	Select, 
	MenuItem,
	Link, 
	Grid, 
	Paper, 
	Box, 
	Typography, 
	Container, 
	Checkbox, 
	FormControlLabel, 
	TextField, 
	CssBaseline, 
	Button, 
	Divider 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles= makeStyles((theme) => ({
  paper: {
	  padding: "30px"
  }
}));

export default function UpdatePasswordForm({
	onSubmit,
	user
}) {
  const classes = useStyles();

  const 
	[password, setPassword] = useState(null),
	[confirm, setConfirm] = useState(null)

  function updatePass() {
		onSubmit({password, confirm})
	
  }

  return (
	  <Paper style={useStyles.paper}>
		  <div className={classes.paper}>
			<form className={classes.form} noValidate>
			  <Grid container spacing={2}>
	  			<Grid item xs={12}>
					<Typography variant="h5">Modifier mon mot de passe</Typography>
	  			</Grid>	
	  			<Grid item xs={12}>
	  				<Divider />
	  			</Grid>	
				<Grid item xs={12}>
				  <TextField
					variant="outlined"
					required
					fullWidth
					name="password"
					label="Mot de passe"
					type="password"
	  				onChange={(e)=>setPassword(e.target.value)}
				  />
				</Grid>
				<Grid item xs={12}>
				  <TextField
					variant="outlined"
					required
					fullWidth
					name="confirm"
					label="Conirmation"
					type="password"
	  				onChange={(e)=>setConfirm(e.target.value)}
				  />
				</Grid>
	  			<Grid item xs={6}>
					  <Button
						fullWidth
						variant="contained"
						color="primary"
	  					onClick={updatePass}
					  >
						Changer le mot de passe
					  </Button>
	  			</Grid>
			  </Grid>
			</form>
		  </div>
	  </Paper>
  );
} 
