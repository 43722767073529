export default function ShortHeader({ title, shortIcon, color }) {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className={"sticky sticky--" + color}>
          <div className="sticky__title">
            <span className="material-icons">{shortIcon}</span>
            <h3>{title.toUpperCase()}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
