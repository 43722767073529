import React, { useState, useEffect } from "react";
import { ShortHeader, ConseilBloc, FormationBloc } from "../components";
import { CircularProgress, Tab, Tabs, Box } from "@material-ui/core";
import { $, formations } from "../services";
import Parcours from "./Parcours";
import Download from "../components/Download";
import Intra from "../components/Intra";
import Catalogue from "../components/Catalogue";

export default function List() {
  const [formationsList, setFormationsList] = useState(null);
  const [activeTab, setActiveTab] = useState("catalogue");
  const [query, setQuery] = useState(localStorage.getItem("query") || "");
  const [axe, setAxe] = useState(0);
  const [month, setMonth] = useState(0);
  const [accordionShow, setAccordionShow] = useState(false);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };
  const handleAxeChange = (e) => {
    setAxe(e.target.value);
  };
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    const searchArray = [];
    if (query !== "") {
      searchArray.push(`q=${query}`);
    }
    if (axe != 0) {
      searchArray.push(`a=${axe}`);
    }
    if (month != 0) {
      searchArray.push(`m=${month}`);
    }
    // console.log(`searchArray.join("&")`, searchArray.join("&"));
    setAccordionShow(true);
    $.get(`/formations?${searchArray.join("&")}`).then((data) =>
      setFormationsList(data.data.formations)
    );
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (query === "") {
      setAccordionShow(false);
    }
    if (formationsList === null) {
      formations.find((data) => {
        setFormationsList(data.data.formations);
      });
      localStorage.setItem("query", "");
    }
    // if (query !== "") {
    //   $.get(`/formations?q=${query}`).then((data) => {
    //     console.log("$.get", data.data.formations);
    //     setFormationsList(data.data.formations);
    //     setAccordionShow(true);
    //     console.log(`useEffect  formationsList`, formationsList);
    //   });
    // }
  }, []);
  console.log(`query`, query);
  // console.log(`axe`, axe);
  // console.log(`month`, month);
  // console.log(`formationsList`, formationsList);
  return (
    <div>
      {activeTab === "parcours" ? (
        <ShortHeader
          title="Créer mon parcours"
          shortIcon="view_list"
          color="gradient"
        />
      ) : (
        <ShortHeader
          title={
            activeTab === "catalogue" ? "Catalogue" : "Se former en équipe"
          }
          shortIcon="view_list"
          color="gradient"
        />
      )}
      <div className="container">
        <div className="fraction space-t space-b">
          <div className="row my-4">
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs
                id="tabs"
                TabIndicatorProps={{ style: { background: "white" } }}
                value={activeTab}
                onChange={handleTabChange}
                centered
              >
                <Tab className="list-tab" value="catalogue" label="Catalogue" />
                <Tab className="list-tab" value="intra" label="En équipe" />
                <Tab className="list-tab" value="parcours" label="Parcours" />
              </Tabs>
            </Box>
          </div>
          <div className="col-xs-12">
            <div className="List">
              {activeTab === "parcours" && (
                <Parcours formationsList={formationsList} />
              )}
              {activeTab === "catalogue" &&
                (formationsList ? (
                  <Catalogue
                    formationsList={formationsList.filter((elem) =>
                      elem.title.includes(query)
                    )}
                    handleInputChange={handleInputChange}
                    handleAxeChange={handleAxeChange}
                    handleMonthChange={handleMonthChange}
                    handleSearch={handleSearch}
                    query={query}
                    accordionShow={accordionShow}
                  />
                ) : (
                  <CircularProgress />
                ))}
              {activeTab === "intra" && (
                <Intra formationsList={formationsList} />
              )}
              <div className="row justify-content-between mt-5">
                <div className="col-xs-9 col-sm-6 d-none d-sm-block">
                  <div className="">
                    <Download
                      item="Catalogue 2022"
                      path="/files/Catalogue-OCF-2022.pdf"
                    />
                  </div>
                </div>
                <div className="col-sm-3 text-center">
                  <div className="">
                    <a href="#tabs">Retour en haut</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
