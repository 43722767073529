import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Grid,
  Paper,
  Box,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  TextareaAutosize,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import presentiel from "../assets/pics/picto_presentiel.png";
import elearning from "../assets/pics/picto_e-learning.png";
import { makeStyles } from "@material-ui/core/styles";

export default function FormIntra({ formationsList }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "30px",
    },
  }));
  const classes = useStyles();
  const [sended, setSended] = useState(false),
    [dateOfRequest, setDateOfRequest] = useState(""),
    [formationId, setFormationId] = useState(""),
    [formationCustom, setFormationCustom] = useState(null),
    [formationType, setFormationType] = useState("presentiel"),
    [company, setCompany] = useState(null),
    [siret, setSiret] = useState(null),
    [contact, setContact] = useState(null),
    [fonction, setFonction] = useState(null),
    [email, setEmail] = useState(null),
    [context, setContext] = useState(null),
    [objectives, setObjectives] = useState(null),
    [number, setNumber] = useState(null),
    [groupsNumber, setGroupsNumber] = useState(null),
    [participants, setParticipants] = useState(null),
    [constraints, setConstraints] = useState(null),
    [dates, setDates] = useState(null),
    [numberOfDays, setNumberOfDays] = useState(null),
    [location, setLocation] = useState(null),
    [material, setMaterial] = useState(null),
    [handicap, setHandicap] = useState(false),
    [arrangement, setArrangement] = useState(false),
    [informations, setInformations] = useState(null),
    [phone1, setPhone1] = useState(null);
  console.log(
    `formationsList`,
    formationsList.map((elem) => elem._id)
  );
  console.log(`formationType`, formationType);
  return (
    <div className="container">
      <div className="mt-5">
        {/* <h2 className="my-4">Demande de formation intra</h2> */}
        <Paper style={useStyles.paper} elevation={8}>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">
                    Vous voulez organiser une action de formation en intra,
                    veuillez compléter le formulaire suivant:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {/* DATE & FORMATION */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={!dateOfRequest && sended}
                        type="date"
                        name="date"
                        variant="outlined"
                        required
                        fullWidth
                        label="Date de la demande"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => setDateOfRequest(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Formation souhaitée
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formationId}
                          label="Formation souhaitée"
                          onChange={(e) => setFormationId(e.target.value)}
                        >
                          {formationsList?.map((formation) => (
                            <MenuItem value={formation._id}>
                              {formation.title}
                            </MenuItem>
                          ))}
                          <MenuItem value="other">Autre</MenuItem>
                        </Select>
                      </FormControl>

                      {formationId === "other" && (
                        <Grid className="mt-3" item xs={12}>
                          <TextField
                            error={!formationCustom && sended}
                            variant="outlined"
                            required
                            fullWidth
                            multiline
                            name="formationCustom"
                            label="Préciser..."
                            onChange={(e) => setFormationCustom(e.target.value)}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl className="mt-3" component="fieldset">
                        <FormLabel component="legend">
                          Type de formation
                        </FormLabel>
                        <RadioGroup
                          disableRipple
                          aria-label="type de formation"
                          value={formationType}
                          name="formationType"
                          onChange={(e) => setFormationType(e.target.value)}
                        >
                          <FormControlLabel
                            value="presentiel"
                            control={<Radio />}
                            label="Présentiel"
                          />
                          <FormControlLabel
                            value="distanciel"
                            control={<Radio />}
                            label="Distanciel"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* COORDONNEES */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Vos coordonnées :</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={!company && sended}
                        variant="outlined"
                        required
                        fullWidth
                        name="company"
                        label="Etablissement"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={!siret && sended}
                        variant="outlined"
                        required
                        fullWidth
                        label="Numéro SIRET"
                        name="siret"
                        onChange={(e) => setSiret(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={!contact && sended}
                        variant="outlined"
                        required
                        fullWidth
                        label="Contact initiant la demande"
                        name="contact"
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={!fonction && sended}
                        variant="outlined"
                        required
                        fullWidth
                        name="fonction"
                        label="Fonction"
                        onChange={(e) => setFonction(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={!phone1 && sended}
                        variant="outlined"
                        required
                        fullWidth
                        label="Téléphone"
                        name="phone1"
                        onChange={(e) => setPhone1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={!email && sended}
                        variant="outlined"
                        required
                        fullWidth
                        label="Adresse email"
                        name="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {/* CONTEXTE */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Le contexte de votre projet :
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={!context && sended}
                        multiline
                        variant="outlined"
                        fullWidth
                        required
                        label="Les éléments à l'origine de ce projet, contexte, enjeux..."
                        name="context"
                        onChange={(e) => setContext(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Les caractéristiques de votre demande
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={!objectives && sended}
                        multiline
                        variant="outlined"
                        fullWidth
                        required
                        label="Objectifs de la formation et compétences visées"
                        name="objectives"
                        onChange={(e) => setObjectives(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Public concerné par la formation
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={!number && sended}
                        type="number"
                        fullWidth
                        variant="outlined"
                        required
                        label="Nombre de personnes à former"
                        name="objectives"
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        error={!participants && sended}
                        fullWidth
                        variant="outlined"
                        required
                        label="Fonctions ou activités des participants"
                        name="participants"
                        onChange={(e) => setParticipants(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={!groupsNumber && sended}
                        type="number"
                        fullWidth
                        variant="outlined"
                        label="Nombre de groupes prévus"
                        name="groupsNumber"
                        onChange={(e) => setGroupsNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        error={!constraints && sended}
                        fullWidth
                        variant="outlined"
                        required
                        label="Contraintes particulières"
                        name="constraints"
                        onChange={(e) => setConstraints(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* PLANIFICATION */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Planification</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={!numberOfDays && sended}
                        type="number"
                        fullWidth
                        variant="outlined"
                        required
                        label="Nombre de jours"
                        name="numberOfDays"
                        onChange={(e) => setNumberOfDays(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        error={!dates && sended}
                        fullWidth
                        variant="outlined"
                        required
                        label="Période souhaitée / proposition de dates"
                        name="dates"
                        onChange={(e) => setDates(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        error={!location && sended}
                        fullWidth
                        required
                        variant="outlined"
                        label="Lieu de formation prévu"
                        name="location"
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        error={!material && sended}
                        fullWidth
                        variant="outlined"
                        label="Matériel mis à disposition"
                        name="material"
                        onChange={(e) => setMaterial(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* ACCESSIBILITE / HANDICAP */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Accessibilité et situation de handicap
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox value={handicap} color="primary" />}
                        label="Dans le cadre de cette formation, avez-vous à nous signaler
                         des personnes en situation de handicap et/ou à mobilité réduite qui auraient besoin d'un aménagement
                         spécifique pour y participer ?"
                        onChange={(e) => setHandicap(e.target.checked)}
                      />
                    </Grid>
                    {handicap && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox value={arrangement} color="primary" />
                            }
                            label="Avez-vous déjà prévu une solution d'aménagement ?"
                            onChange={(e) => setArrangement(e.target.checked)}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            multiline
                            label="Pour toute question ou renseignement complémentaire"
                            name="informations"
                            onChange={(e) => setInformations(e.target.value)}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary">
                    Envoyer
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}
