import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Grid,
  Paper,
  Box,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  TextareaAutosize,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function FormConseil() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: "30px",
    },
  }));
  const classes = useStyles();
  const [firstname, setFirstname] = useState(null),
    [date, setDate] = useState(null),
    [type, setType] = useState(null),
    [company, setCompany] = useState(null),
    [fonction, setFonction] = useState(null),
    [contact, setContact] = useState(null),
    [email, setEmail] = useState(null),
    [phone1, setPhone1] = useState(null),
    [context, setContext] = useState(null),
    [sended, setSended] = useState(false),
    [allowed, setAllowed] = useState(false);
  return (
    <div className="container">
      <div className="mt-5">
        <h2 className="my-4">Demande d'accompagnement et de conseil :</h2>
        <Paper style={useStyles.paper} elevation={8}>
          <div className={classes.paper}>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Vous voulez organiser une action de conseil auprès de vos
                    équipes, veuillez compléter le formulaire suivant :
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <TextField
                    error={!date && sended}
                    name="date"
                    variant="outlined"
                    required
                    type="date"
                    fullWidth
                    autoFocus
                    label="Date de la demande"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <TextField
                    error={!type && sended}
                    variant="outlined"
                    required
                    multiline
                    fullWidth
                    label="Type d'intervention souhaitée (séminaire, coaching, co-développement...)"
                    name="type"
                    onChange={(e) => setType(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Vos coordonnées :</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={!company && sended}
                        variant="outlined"
                        required
                        fullWidth
                        name="company"
                        label="Etablissement"
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={!contact && sended}
                        variant="outlined"
                        required
                        fullWidth
                        label="Contact"
                        name="contact"
                        onChange={(e) => setContact(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={!fonction && sended}
                        variant="outlined"
                        required
                        fullWidth
                        label="Fonction"
                        name="fonction"
                        onChange={(e) => setFonction(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="phone_1"
                        label="Téléphone"
                        onChange={(e) => setPhone1(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        error={!email && sended}
                        variant="outlined"
                        required
                        fullWidth
                        label="Adresse email"
                        name="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Le contexte de votre projet :
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!context && sended}
                    multiline
                    variant="outlined"
                    style={{ width: "100%" }}
                    required
                    label="Les éléments à l'origine de ce projet, contexte, enjeux..."
                    name="context"
                    onChange={(e) => setContext(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button variant="contained" color="primary">
                    Envoyer
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}
