import React from "react";
import Markdown from "markdown-react-js";

export default function FormationContent({
  formation_goals,
  pedagogical_goals,
  content,
  pedagogical_method,
  evaluation_method,
}) {
  return (
    <div className="col-xs-12">
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h3 className="accordion-header" id="panelsStayOpen-headingOne">
            <div
              className="TextBlock accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseOne"
            >
              <h3 className="TextBlock__Title h-title-color1">
                <i className="fa fa-project-diagram"></i>Objectifs de formations
                et compétences visées
              </h3>
            </div>
          </h3>
          <div
            id="panelsStayOpen-collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="panelsStayOpen-headingOne"
          >
            <div className="accordion-body TextBlock">
              {formation_goals?.map((elem) => (
                <Markdown text={elem} />
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="panelsStayOpen-headingTwo">
            <div
              className="accordion-button collapsed TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseTwo"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseTwo"
            >
              <h3 className="TextBlock__Title h-title-color2">
                <i className="fa fa-bullseye"></i>Objectifs pédagogiques
              </h3>
            </div>
          </h3>
          <div
            id="panelsStayOpen-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingTwo"
          >
            <div className="accordion-body TextBlock">
              {pedagogical_goals?.map((elem) => (
                <Markdown text={elem} />
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="panelsStayOpen-headingThree">
            <div
              className="accordion-button collapsed TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseThree"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseThree"
            >
              <h3 className="TextBlock__Title h-title-color3">
                <i className="fas fa-book-open"></i>Contenu
              </h3>
            </div>
          </h3>
          <div
            id="panelsStayOpen-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingThree"
          >
            <div className="accordion-body TextBlock">
              {content?.map((elem) => (
                <Markdown text={elem} />
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="panelsStayOpen-headingFour">
            <div
              className="accordion-button collapsed TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFour"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseFour"
            >
              <h3 className="TextBlock__Title h-title-color5">
                <i className="fas fa-tools"></i>Méthodes et outils pédagogiques
              </h3>
            </div>
          </h3>
          <div
            id="panelsStayOpen-collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFour"
          >
            <div className="accordion-body TextBlock">
              {pedagogical_method?.map((elem) => (
                <Markdown text={elem} />
              ))}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="panelsStayOpen-headingFive">
            <div
              className="accordion-button collapsed TextBlock"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFive"
              aria-expanded="true"
              aria-controls="panelsStayOpen-collapseFive"
            >
              <h3 className="TextBlock__Title h-title-color4">
                <i className="fas fa-toolbox"></i>Méthodes d'évaluation
              </h3>
            </div>
          </h3>
          <div
            id="panelsStayOpen-collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingFive"
          >
            <div className="accordion-body TextBlock">
              {evaluation_method?.map((elem) => (
                <Markdown text={elem} />
              ))}
            </div>
          </div>
        </div>
        <div className="text-right">
          <span
            className="custom-link text-primary"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Retour en haut
          </span>
        </div>
      </div>
    </div>
  );
}
