import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Grid,
  Paper,
  Box,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "30px",
  },
}));

export default function FormStagiaire() {
  const classes = useStyles();

  return (
    <Paper style={useStyles.paper} elevation={8} className="mt-5">
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Stagiaire</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // error={!firstname && sended}
                name="firstname"
                variant="outlined"
                required
                fullWidth
                label="Prénom"
                autoFocus
                // onChange={(e) => setFirstname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // error={!lastname && sended}
                variant="outlined"
                required
                fullWidth
                label="Nom de famille"
                name="lastname"
                // onChange={(e) => setLastname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                // error={!email && sended}
                variant="outlined"
                required
                fullWidth
                label="Adresse email"
                name="email"
                autoComplete="email"
                // onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                // error={!fonction && sended}
                variant="outlined"
                required
                fullWidth
                name="fonction"
                label="Fonction"
                // onChange={(e) => setFonction(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl variant="outlined" fullWidth value="">
                <InputLabel id="demo-simple-select-outlined-label">
                  Situation
                </InputLabel>
                <Select
                  required
                  // error={!situation && sended}
                  labelId="demo-simple-select-outlined-label"
                  label="situation"
                  // onChange={(e) => {
                  //   setStatus(e.target.value);
                  // }}
                  defaultValue="student"
                >
                  <MenuItem value={"student"}>Étudiant</MenuItem>
                  <MenuItem value={"employee"}>Salarié</MenuItem>
                  <MenuItem value={"other"}>Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
}
