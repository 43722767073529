import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import qualiopi from "../assets/pics/Logo_qualiopi.png";

export default function Footer() {
  return (
    <footer className="bg-white mt-5">
      <div className="col-md-12">
        <div className="gradient row">
          <div className="col-lg-10 mx-auto text-white text-center footer__bar">
            <div className="row">
              <div className="col-md-4">
                <p className="lead mb-0 footer__infos">
                  <span className="material-icons">email</span>
                  <a
                    style={{ color: "white" }}
                    href="mailto:contact-ocf-formation@oppelia.fr"
                  >
                    contact-ocf-formation@oppelia.fr
                  </a>
                </p>
              </div>
              <div className="col-md-4">
                <p className="lead mb-0 footer__infos">
                  <span className="material-icons">call</span>01 43 44 67 21
                </p>
              </div>
              <div className="col-md-4">
                <p className="lead mb-0 footer__infos">
                  <span className="material-icons">local_shipping</span>60 rue
                  du Rendez-Vous
                  <br />
                  75012 PARIS
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <div className="row py-4">
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <Link to="/">
              <img src={logo} class="logo logo--footer" height="80" />
            </Link>
            <p className="font-italic text-muted">
              OC&F est un organisme de formation et de conseil du secteur{" "}
              <br></br>médico-psycho-sociale, expert dans le champ de
              l’addictologie.
            </p>
            <img
              className="img-fluid"
              src={qualiopi}
              alt="logo qualiopi"
              width="300px"
            />
          </div>
          <div className="col-lg-6 col-md-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.6094774639214!2d2.3991524156739508!3d48.84658727928633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6727aeb4f3c6d%3A0x2a2889b65793fa61!2s60%20Rue%20du%20Rendez-Vous%2C%2075012%20Paris%2C%20France!5e0!3m2!1sen!2sca!4v1637845622849!5m2!1sen!2sca"
              width="400"
              height="300"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
          {/* <div className="col-lg-2 col-md-6 mb-4 mb-lg-0 ">
            <h6 className="text-uppercase font-weight-bold mb-4">
              Plan du site
            </h6>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <a href="#" class="text-muted">
                  Accueil
                </a>
              </li>
              <li className="mb-2">
                <a href="#" class="text-muted">
                  Liste des formations
                </a>
              </li>
              <li className="mb-2">
                <a href="#" class="text-muted">
                  Conseils
                </a>
              </li>
              <li className="mb-2">
                <a href="#" class="text-muted">
                  À propos
                </a>
              </li>
              <li className="mb-2">
                <a href="#" class="text-muted">
                  Contact
                </a>
              </li>
            </ul>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 mb-lg-0">
            <h6 className="text-uppercase font-weight-bold mb-4">
              Rechercher une formation
            </h6>
            <div className="p-1 rounded border">
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Ex : Bases en hypnose ericksonnienne appliquées en addictologie"
                  aria-describedby="button-addon1"
                  className="form-control border-0 shadow-0"
                ></input>
                <div className="input-group-append">
                  <button
                    id="button-addon1"
                    type="submit"
                    className="btn btn-link"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="bg-light py-4">
        <div className="container text-center">
          <p className="text-muted mb-0 py-2">© 2022 All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
