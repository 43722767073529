import { Link } from "react-router-dom";
import { useState } from "react";

export default function Banner({ number }) {
  const [query, setQuery] = useState("");
  const search = (e) => {
    // e.preventDefault();
    localStorage.setItem("query", query.toLowerCase());
  };
  return (
    <div className="container-fluid nomargin banner">
      <div className="row">
        <div className="col-md-12">
          <div className="banner__search">
            <div className="row">
              <div className="banner__search__title">
                <h3>
                  <strong>
                    <span className="textBigger font-weight-normal">OC&F </span>
                  </strong>
                  est un organisme de <strong className="">formation</strong> et
                  de <strong className="">conseil</strong> du secteur
                  médico-psycho-social, expert dans le champ de l'
                  <strong className="">addictologie</strong>.
                </h3>
              </div>
            </div>
            <form onSubmit={(e) => search()}>
              <div className="row">
                <div className="col-md-11 nopadding">
                  <div className="banner__search__input">
                    <input
                      value={query}
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                      type="text"
                      placeholder="Rechercher une formation"
                    />
                  </div>
                </div>
                <div className="col-md-1 nopadding">
                  <div className="banner__search__submit">
                    <Link to={{ pathname: "/formations", state: query }}>
                      <span
                        onClick={(e) => search()}
                        className="material-icons"
                      >
                        search
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
