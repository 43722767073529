import React from "react";
import { Download } from "../components";

export default function SearchBar({
  handleInputChange,
  handleAxeChange,
  handleMonthChange,
  handleSearch,
}) {
  return (
    <form>
      <div className="form-group row mb-5 mt-2">
        <div className="col-md-3 col-6">
          <input
            class="form-control"
            placeholder="Recherche par mot-clé"
            onChange={handleInputChange}
          ></input>
        </div>
        <div className="col-md-3 col-6">
          <select class="form-control" onChange={handleAxeChange}>
            <option selected value={0}>
              Tous les axes
            </option>
            <option value={1}>
              Approches d’accompagnement expérientielles et innovantes
            </option>
            <option value={2}>Troubles co-occurents et/ou spécifiques</option>
            <option value={3}>
              Prévention, intervention précoce et réduction des risques
            </option>
            <option value={4}>
              Transdiscplinarité et coopération avec les usagers
            </option>
          </select>
        </div>
        <div class="col-md-3 col-6">
          <select class="form-control" onChange={handleMonthChange}>
            <option selected value={0}>
              Tous les mois
            </option>
            <option value={"1"}>Janvier</option>
            <option value={"2"}>Février</option>
            <option value={"3"}>Mars</option>
            <option value={"4"}>Avril</option>
            <option value={"5"}>Mai</option>
            <option value={"6"}>Juin</option>
            <option value={"7"}>Juillet</option>
            <option value={"8"}>Août</option>
            <option value={"9"}>Septembre</option>
            <option value={"10"}>Octobre</option>
            <option value={"11"}>November</option>
            <option value={"12"}>Décembre</option>
          </select>
        </div>
        <div class="col-md-2 col-3">
          <div className="">
            <button
              type="submit"
              onClick={handleSearch}
              className="btn btn-primary"
            >
              Rechercher
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
