import { useState } from "react"
import { CircularProgress, Button, Paper, Typography, Grid, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { sessions as sess } from "../services"

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});


export default function History({
  sessions,
  setDocs
}) {

  const [list, setList] = useState(null)

  if (!list)
    sess.find((data) => {
      let tmp = []
      for (let s of sessions) {
        tmp.push(data.data.find(e => e.id === s))
      }
      setList(tmp)
    })


  return (
    <Paper style={{ padding: "30px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Historique des sessions</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>

          {list ?
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  {list.map((row, idx) => (
                    <StyledTableRow key={idx}>
                      <StyledTableCell align="left">
                        <div className="SessionItem">
                          <b>{row.formation.title}</b>
                          <p>{row.label}</p>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell><Button
                        onClick={() => setDocs(row.documents)}
                      >
                        DOCS
						  </Button></StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            : <p>Vous n'êtes inscrit à aucune session</p>}
        </Grid>
      </Grid>
    </Paper>
  )
}
