import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import ParcoursBloc from "../components/ParcoursBloc";
import parcours_1 from "../assets/pics/parcours_1.png";
import parcours_2 from "../assets/pics/parcours_2.png";
import trousseau_3 from "../assets/pics/trousseau_3.png";

export default function Parcours({ formationsList }) {
  const [selection, setSelection] = useState([]);

  const handleCheckBoxChange = (e, title, breakfast) => {
    console.log(`breakfast`, breakfast);
    if (e.target.checked) {
      setSelection([...selection, !breakfast ? title : `P'tit déj ${title}`]);
    } else {
      const indexToRemove = selection.indexOf(title);
      const modifiedSelection = [...selection];
      modifiedSelection.splice(indexToRemove, 1);
      setSelection(modifiedSelection);
    }
  };

  console.log(`selection`, selection);
  return (
    <>
      <div className="Title__formation mt-5 mb-5">
        <h1 className="my-3">Des parcours thématiques à composer</h1>
      </div>
      <p>
        Selon les différents axes, les stagiaires peuvent choisir de composer un
        parcours de formation, à la carte afin de renforcer leur pratiques à
        partir de formations{" "}
        <span className="text-bold">
          {" "}
          "clé d'entrée" et/ou "clés de progression" :
        </span>
      </p>
      <ul>
        <li>
          Les formations dites <span className="fw-bold">clé d'entrée</span>{" "}
          permettent de renforcer le langage commun, acquérir les fondamentaux
          de la thématique et renforcer les professionnels dans leur posture.
        </li>
        <li>
          Les formations dites{" "}
          <span className="text-bold">clés de progression</span>
          exigent d'avoir déjà suivi une formation "clé d'entrée" au préalable
          et permettent d'aller plus loin dans des mises en pratique, des focus
          et/ou des programmes à animer.
        </li>
      </ul>
      <p>
        Ainsi, en composant un parcours, le stagiaire peut progresser sur un axe
        selon ses besoins de terrain et développer des programmes spécifiques.
      </p>
      <div className="row justify-content-center mt-4">
        <img
          className="img-fluid my-3"
          style={{ width: "100px" }}
          src={trousseau_3}
          alt="trousseau de clé"
        />
      </div>
      {/* <img
        className="img-fluid my-3 d-none d-md-block"
        src={parcours_1}
        alt="exemple de parcours de éducation préventive et intervention précoce"
      />
      <img
        className="img-fluid d-none d-md-block"
        src={parcours_2}
        alt="exemple de parcours réduction des risques"
      /> */}
      <hr className="my-5" />
      <p>
        Vous avez choisi :
        <ul>
          {selection?.map((formation) => (
            <li>{formation}</li>
          ))}
        </ul>
      </p>
      <hr className="my-5" />
      {formationsList ? (
        formationsList
          .filter((elem) => elem.axe_id !== 5)
          .map((formation, index) => (
            <>
              <ParcoursBloc
                key={index}
                title={formation.title}
                state={formation}
                handleCheckBoxChange={handleCheckBoxChange}
              />
              {formation.breakfast ? (
                <ParcoursBloc
                  title={formation.title}
                  state={formation.breakfast}
                  handleCheckBoxChange={handleCheckBoxChange}
                  breakfast={formation.breakfast}
                />
              ) : null}{" "}
            </>
          ))
      ) : (
        <CircularProgress />
      )}
    </>
  );
}
