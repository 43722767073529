import { useState } from "react";
import { ShortHeader, RegisterForm, LoginForm } from "../components";
import { Alert } from "@material-ui/lab";
import { auth } from "../services";

export default function Login({ setLogged, setAlert }) {
  const [loginFail, setLoginFail] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(null);
  const [error, setError] = useState("");

  function registerDone(data) {
    if (data) {
      console.log(data);
      if (data.status == 201) {
        setRegisterSuccess(true);
        console.log(data);
      } else {
        setRegisterSuccess(false);
        setError(data.data.message[0].messages[0].message);
      }
    }
  }
  function loginDone(data) {
    if (data.status == 200) {
      setLogged(true);
      setAlert("Vous êtes à présent connecté");
    } else {
      setLoginFail(true);
    }
  }

  return (
    <div>
      <ShortHeader
        title="S'authentifier"
        shortIcon="fingerprint"
        color="blue"
      />

      <div className="container">
        <div className="row space-t space-b">
          <div className="justify-content-center">
            <div className="col-sm-12 col-lg-6 space-b">
              {loginFail ? (
                <Alert severity="error" className="space-b">
                  Email ou mot de passe invalide.
                </Alert>
              ) : null}
              <LoginForm
                onSubmit={(fields) =>
                  auth.login(fields, (data) => loginDone(data))
                }
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-6">
            {registerSuccess === true ? (
              <Alert severity="success" className="space-b">
                Un email vous a été envoyé pour confirmer votre inscription.
              </Alert>
            ) : null}
            {registerSuccess === false ? (
              <Alert severity="error" className="space-b">
                {error}
              </Alert>
            ) : null}
            {/* <RegisterForm
              onSubmit={(fields) =>
                auth.register(fields, (data) => registerDone(data))
              }
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
