import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { convertDate } from "../services";
import { NextSession } from ".";

export default function Preview({ formations, displayed = 3 }) {
  function load(formations) {
    console.log(formations);
    if (formations)
      return formations.slice(0, displayed).map((formation, index, arr) => (
        <Link
          to={{
            pathname: `/formation`,
            state: formation,
          }}
          className="list__element"
          key={index}
          // target="_blank"
          // rel="noopener noreferrer"
        >
          <div className="list__element__title">
            <h5>{formation.title}</h5>
          </div>
          <div className="list__element__date">
            <p>{convertDate(formation.sessions[0].startDate)}</p>
            {/* <NextSession sessions={formation.sessions} /> */}
          </div>
          {index !== displayed - 1 || index !== arr.length - 1 ? (
            <hr className="customHR" />
          ) : null}
        </Link>
      ));
    else return <CircularProgress />;
  }

  return (
    <div className="">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="preview__blocs">
            <div className="row">
              <div className="col-md-6">
                <div className="preview__blocs__infos">
                  <h3>Découvrez nos prochaines sessions de formation</h3>
                  <p>
                    Nous enrichissons régulièrement notre catalogue de nouvelles
                    formations.
                  </p>
                  <Link to="/formations" className="basicLink">
                    Accéder a la liste des formations{" "}
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="preview__blocs__list">{load(formations)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
