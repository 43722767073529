export default function Download({ item, path }) {
  return (
    <div className="col-md-6">
      <div className="download__element">
        <div className="element__title">
          <p>{item}</p>
        </div>
        <a href={path} target="_blank">
          <div className="element__icon">
            <span className="material-icons">file_download</span>
          </div>
        </a>
      </div>
    </div>
  );
}
