import { useState } from "react";
import { Register, Header, Footer } from "./components";
import { Switch, Route } from "react-router-dom";
import {
  sessions,
  formations,
  categories,
  counsels,
  feeds,
  auth,
  users,
  candidates,
} from "./services";
import {
  Home,
  Conseil,
  List,
  Formation,
  About,
  NoMatch,
  Login,
  Contact,
  Account,
} from "./pages";
import FormationById from "./pages/FormationById";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*
 * SETUP COMPONENT
 * ---------------
 */

function App() {
  let jwt = sessionStorage.getItem("jwToken");
  const [logged, setLogged] = useState(jwt != null);
  const [formationsList, setFormationsList] = useState(null);
  const [feedsList, setFeedsList] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);

  if (formationsList === null) {
    formations.find((data) => {
      setFormationsList(data.data.formations);
    });
  }

  if (feedsList === null) {
    feeds.find((data) => {
      setFeedsList(data.data.feeds);
    });
  }

  function handleClose() {
    setAlertMsg(null);
  }
  return (
    <div className="App">
      <Header logged={logged} setLogged={setLogged} setAlert={setAlertMsg} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertMsg != null}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info">
          {alertMsg}
        </Alert>
      </Snackbar>

      <Switch>
        <Route path="/" exact>
          <Home formations={formationsList} feeds={feedsList} />
        </Route>
        <Route path="/conseil" component={Conseil} exact />
        <Route path="/formations" exact>
          <List formations={formationsList} />
        </Route>
        <Route path="/formation" component={Formation} exact />
        <Route path="/formation/:id" component={FormationById} />
        <Route path="/apropos" component={About} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/account" exact>
          {logged ? (
            <Account />
          ) : (
            <Login setLogged={setLogged} setAlert={setAlertMsg} />
          )}
        </Route>
        <Route path="*" component={NoMatch} exact />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
