import { Fragment, useState, useEffect } from "react";
import approfondissement from "../assets/pics/Picto_approfondissement.jpg";
import outils from "../assets/pics/Picto_outils.jpg";
import posture from "../assets/pics/Picto_posture_prof.jpg";
import nouvelle_formation from "../assets/pics/picto_new.png";
import presentiel from "../assets/pics/picto_presentiel.png";
import elearning from "../assets/pics/picto_e-learning.png";
import exploration from "../assets/pics/picto_exploration.png";
import Markdown from "markdown-react-js";
import {
  Select,
  TextField,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { candidates, users, apiURL, convertDate } from "../services";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ShortHeader } from "../components";
import { Link } from "react-router-dom";
import FormationContent from "../components/FormationContent";
import FormIndividual from "../components/FormIndividual";

export default function FormationById({ match }) {
  console.log(`match.params.id`, match.params.id);
  const [formation, setFormation] = useState(null);
  const [user, setUser] = useState(null);

  // if (user === null) {
  //   users.me((data) => {
  //     setUser(data);
  //   });
  // }

  const [session, setSession] = useState(null);
  const [number, setNumber] = useState(1);
  const [formVisible, setFormVisible] = useState(false);
  const [eexist, setEexist] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const breakfast = formation.sessions.find(elem => elem.type === "breakfast")
  const duration =
    formation.sessions.length === 0
      ? formation.duration
      : formation.sessions[0].duration;

  function SessionsList(sessions) {
    return sessions?.map((session, index) => {
      return (
        <MenuItem value={session._id}>
          <b style={{ marginRight: "5px" }}>{session.location.toUpperCase()}</b>{" "}
          - {convertDate(session.startDate)}
        </MenuItem>
      );
    });
  }

  function handleChange(e) {
    setSession(e.target.value);
  }

  function handleNumber(e) {
    setNumber(e.target.value);
  }

  function displayButton(formation, session, done) {
    if (sessionStorage.getItem("jwToken")) {
      if (!done) {
        return (
          <div className="Session">
            <FormControl variant="outlined" style={{ width: "250px" }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Session
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label="Session"
                onChange={handleChange}
              >
                <MenuItem value=""></MenuItem>
                {SessionsList(formation.sessions)}
              </Select>
            </FormControl>
            {user && user.role.name === "Employeur" ? (
              <TextField
                id="outlined-basic"
                label="Combien de stagiaires ?"
                type="number"
                onChange={handleNumber}
                style={{ marginLeft: "10px" }}
                variant="outlined"
              />
            ) : null}
            <Button
              fullHeight
              variant="contained"
              color="primary"
              size="large"
              endIcon={<CheckCircleOutlineIcon />}
              onClick={() => {
                candidates.create(session, number, (data) => {
                  if (data.status === 409) {
                    setEexist(true);
                  } else {
                    setDone(true);
                  }
                });
              }}
            >
              S'inscrire
            </Button>
          </div>
        );
      } else {
        return (
          <Alert className="space-t" severity="success">
            Votre candidature à bien été prise en compte
          </Alert>
        );
      }
    } else {
      return null;
    }
  }

  // if (!formation) {
  //   return null;
  // }

  if (formation) {
    return (
      <div>
        <ShortHeader title="" shortIcon="view_list" color="gradient" />
        <div className="container">
          <div className="fraction space-t space-b">
            <div className="col-xs-12 space-b">
              <div className="Title__formation mt-4">
                <h1 id="title">{formation.title}</h1>
                <div className="row justify-content-center">
                  {formation.icon && (
                    <img
                      className="img-fluid my-4"
                      style={{ width: "120px" }}
                      src={`${apiURL}/${formation.icon}`}
                      alt={`Logo niveau ${formation.level.toString()}`}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* FORMATION DESCRIPTION */}
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="Metadata__Item">
                  <span className="Metadata__Title h-title-color2">
                    <i class="fas fa-book-open"></i> Axe {formation.axe_id}
                  </span>
                  <Markdown text={formation.axe} />
                </div>
                {formation.breakfast && (
                  <div className="Metadata__Item">
                    <span className="Metadata__Title h-title-color2">
                      <i className="fas fa-coffee"></i> Petit déjeuner
                    </span>
                    <span className="Metadata__Text">
                      <dt>Date : {convertDate(formation.breakfast.date)}</dt>
                    </span>
                    <span className="Metadata__Text">
                      Prix : {formation.breakfast.price} €
                    </span>
                  </div>
                )}
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <div className="Metadata__Item">
                  <span className="Metadata__Title">
                    <i className="fa fa-user-friends"></i> Public concerné
                  </span>
                  <span className="Metadata__Text">{formation.public}</span>
                </div>
                {formation.prerequisite ? (
                  <div className="Metadata__Item">
                    <span className="Metadata__Title">
                      <i className="fa fa-book"></i> Prérequis
                    </span>
                    <span className="Metadata__Text">
                      {formation.prerequisite}
                    </span>
                  </div>
                ) : null}
                <div className="Metadata__Item">
                  <span className="Metadata__Title">
                    <i className="fa fa-calendar-minus"></i> Sessions
                  </span>
                  <span className="Metadata__Text">
                    <dl>
                      {formation.open_for_inter ? (
                        formation.sessions.map((session, index) => {
                          const { location, startDate } = session;
                          return (
                            <Fragment>
                              <dt>Date : {convertDate(startDate)}</dt>
                              <dd>Lieu : {location}</dd>
                              {/* <dt></dt>
                            <dd></dd> */}
                            </Fragment>
                          );
                        })
                      ) : (
                        <>
                          <dt>Formation Intra</dt>
                          <dd>sur devis</dd>
                        </>
                      )}
                    </dl>
                  </span>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12">
                <div className="Metadata__Item">
                  <span className="Metadata__Title h-title-color2">
                    <i className="fas fa-clock"></i> Horaires
                  </span>
                  <span className="Metadata__Text">09h30 - 13h00</span>
                  <span className="Metadata__Text">14h00 - 17h30</span>
                </div>
                <div className="Metadata__Item">
                  <span className="Metadata__Title h-title-color2">
                    <i className="fa fa-hourglass-half"></i> Durée
                  </span>

                  <span className="Metadata__Text">
                    {duration.toString() + " jours"} ({duration * 7} heures)
                  </span>
                  {/* <span className="Metadata__Text">
                      {formation.sessions[0].duration > 1
                        ? formation.sessions[0].duration + " jours"
                        : "Une journée"}{" "}
                      ({formation.sessions[0].duration === 3 ? 21 : 14} heures)
                    </span> */}
                </div>
                <div className="Metadata__Item ">
                  <span className="Metadata__Title h-title-color2">
                    <i className="fas fa-euro-sign"></i> Tarifs
                  </span>
                  <dl>
                    {formation.price_continuing && (
                      <>
                        <dt>Formation continue</dt>
                        <dd>{formation.price_continuing} €</dd>
                      </>
                    )}
                    {formation.price_individual && (
                      <>
                        <dt>Formation individuelle</dt>
                        <dd>{formation.price_individual} €</dd>
                      </>
                    )}
                    <dt>Formation Intra</dt>
                    <dd>sur devis</dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="">
                {/* <button className="btn"> */}
                <i class="far fa-arrow-alt-circle-left"></i>{" "}
                <Link to="/formations">
                  <span className="">Retour à la liste</span>
                </Link>
                {/* </button> */}
              </div>
            </div>
            <hr className="my-5" />

            {/* FORMULAIRE */}
            {formation.open_for_inter && (
              <>
                <div className="Session justify-content-center">
                  <FormControl variant="outlined" style={{ width: "250px" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Session
                    </InputLabel>
                    {formation.sessions && (
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Session"
                        value={session}
                        onChange={handleChange}
                      >
                        <MenuItem value=""></MenuItem>
                        {SessionsList(formation.sessions)}
                      </Select>
                    )}
                  </FormControl>
                  {/* <TextField
                id="outlined-basic"
                label="Combien de stagiaires ?"
                type="number"
                value={number}
                onChange={handleNumber}
                style={{ marginLeft: "10px" }}
                variant="outlined"
              /> */}
                  <Button
                    fullHeight
                    variant="contained"
                    color="primary"
                    size="large"
                    endIcon={<CheckCircleOutlineIcon />}
                    onClick={() => {
                      setFormVisible(true);
                    }}
                  >
                    S'inscrire
                  </Button>
                </div>
                {session && <FormIndividual />}
                <hr className="my-5" />
              </>
            )}

            {/* CONTENT ACCORDIONS */}
            <FormationContent
              formation_goals={formation.formation_goals}
              pedagogical_goals={formation.pedagogical_goals}
              content={formation.content}
              pedagogical_method={formation.pedagogical_method}
              evaluation_method={formation.evaluation_method}
            />

            {/* <div className="col-sm-12">{displayButton(formation, session, done)}</div>
            {eexist ? (
              <div className="col-sm-12">
                <Alert className="space-t" severity="warning">
                  Vous avez déjà candidaté à cette formation
                </Alert>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }
}
