import { CircularProgress, Button, Paper, Typography, Grid, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

export default function DocView({
	docs
}) {
		function displayDoc(docs) {
				if(docs === null) {
						return (<p>Il n'y a aucun document associé à cette session de formation.</p>)
				}
        else {
            return (
                <ul>
									{docs.map((elm, idx) => (
										<li>
										  <a href={"http://188.165.104.163:1337" + elm.url} target="_blank">{elm.name}</a>
										</li>
									))}
                </ul>
            )
        }
    }
		
		return (
		<Paper style={{ padding: "30px" }}>
				<Grid container spacing={2}>
				<Grid item xs={12}>
						<Typography variant="h4">Document</Typography>
				</Grid>	
				<Grid item xs={12}>
						<Divider />
				</Grid>	
				<Grid item xs={12}>
          { displayDoc(docs) }
				</Grid>	
				</Grid>
		</Paper>
		)
}
